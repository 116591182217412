import React from 'react';
import _t from 'counterpart';
import { useQuery } from 'react-query';
import { CAlert } from '@coreui/react';
import { formatCurrency } from '../../helpers';
import { loadWalletBalance } from '../../services/BackendService';

const Balance = (props: { walletId?: string; value?: string | undefined; currency?: string | undefined }) => {
	const enabled = props.value === undefined;
	const { isLoading, error, data } = useQuery<any, Error>(
		['wallet-info', props.walletId], () => loadWalletBalance(props.walletId!),
		{ enabled }
	);

	if (isLoading && enabled) {
		return <span>{_t('global.please-wait')}</span>;
	}

	if (error && enabled) {
		return <CAlert color="danger">{error.message}</CAlert>;
	}

	if (!enabled) {
		return <span>{formatCurrency(props.value || 0, props.currency)}</span>;
	}

	return <span>{formatCurrency(data.balance, data.currency)}</span>;
};

export default Balance;
