import React, { useState } from 'react';
import { CCard, CCardBody, CRow, CCol, CCardHeader, CCardTitle, CButton } from '@coreui/react';
import { Link, useParams } from 'react-router-dom';
import { loadCustomer, loadTransaction, refundWithdrawal } from '../services/BackendService';
import { TransactionStatusBadge } from './transactions/TransactionStatusBadge';
import { formatCurrency, extractErrorMessage, getIconNameForCountry, formatDateTime } from '../helpers';
import _t from 'counterpart';
import { useMutation, useQuery } from 'react-query';
import { TransactionStatus, IWithdrawal } from './transactions/types';
import CIcon from '@coreui/icons-react';
import Loading from '../components/Loading';
import Error from '../components/Error';
import PageLayout from '../components/PageLayout';
import ButtonWrapper from '../components/ButtonWrapper';
import UpdateWithdrawalStateModal from './transactions/UpdateWithdrawalStateModal';
import WithdrawalStatusBadge from './transactions/WithdrawalStatusBadge';
import toast from 'react-hot-toast';

const TransactionPage = () => {
	const params = useParams();

	const { id } = params as any;
	interface ISelectedWithdrawal {
		withdrawal: IWithdrawal;
		tableActionType: 'approve' | 'reject';
	}

	const [refundedWithdrawal, setRefundedWithdrawal] = useState<string | null>(null);
	const [selectedWithdrawal, setSelectedWithdrawal] = useState<ISelectedWithdrawal | null>(null);

	const loadTransactionQuery = useQuery(['transaction', id], () => loadTransaction(id || ''), {
		onError: (e: any) => {
			const error = extractErrorMessage(e);
			toast.error(error);
		},
		retry: false,
	});

	const adminId = loadTransactionQuery.data?.withdrawalReview?.reviewerId;
	const loadAdminQuery = useQuery(['transaction-reviewer', id], () => loadCustomer(adminId), {
		enabled: adminId !== undefined,
	});
	const admin = loadAdminQuery.data;

	const refundMutation = useMutation(['refund'], (id: string) => refundWithdrawal(id), {
		onSuccess: () => {
			toast.success(_t('transactions.successful-refund'));
			loadTransactionQuery.refetch();
		},
		onError: (error: any) => {
			const msg = extractErrorMessage(error);
			toast.error(msg);
		},
	});

	const showWithdrawalModal = (tableActionType: 'approve' | 'reject', withdrawal: IWithdrawal) => {
		setSelectedWithdrawal({ withdrawal, tableActionType });
	};

	const refund = (id: string) => {
		setRefundedWithdrawal(id);
		refundMutation.mutate(id);
	};

	const onWithdrawalChangeStateClose = (refetchData: boolean) => {
		if (refetchData) {
			loadTransactionQuery.refetch();
		}
		setSelectedWithdrawal(null);
	};

	const getCountryIcon = (countryCode: string) => {
		return <CIcon name={getIconNameForCountry(countryCode.toLowerCase())!} size="xl" title={countryCode} />;
	};

	if (loadTransactionQuery.isLoading) {
		return <Loading />;
	}

	if (loadTransactionQuery.isError) {
		return <Error onRetry={loadTransactionQuery.refetch} />;
	}

	const transaction = loadTransactionQuery.data;

	return (
		<PageLayout
			title={_t.translate('transaction.title')}
			titleAppend={
				<CRow className="float-right">
					{transaction?.type === 'WITHDRAWAL' && transaction?.status === TransactionStatus.Pending && (
						<>
							<CButton onClick={() => showWithdrawalModal('approve', transaction)} className="mr-2" color="success">
								{_t('action.approve')}
							</CButton>
							<CButton onClick={() => showWithdrawalModal('reject', transaction)} className="mr-2" color="danger">
								{_t('action.reject')}
							</CButton>
						</>
					)}
					{transaction?.type === 'WITHDRAWAL' && transaction.status === TransactionStatus.Successful && (
						<ButtonWrapper
							isLoading={refundMutation.isLoading && refundedWithdrawal === transaction?.id}
							onClick={() => refund(transaction?.id)}
							className="mr-1"
							color="primary"
						>
							{_t('action.refund')}
						</ButtonWrapper>
					)}
				</CRow>
			}
		>
			<CRow>
				<CCol lg={6}>
					<CCard>
						<CCardHeader>
							<CCardTitle className="float-left">{_t('global.details')}</CCardTitle>
							<div className="float-right">
								{transaction.type !== 'WITHDRAWAL' && (
									<TransactionStatusBadge status={transaction?.status || TransactionStatus.Failed} />
								)}
								{transaction.type === 'WITHDRAWAL' && (
									<WithdrawalStatusBadge status={transaction?.withdrawalReview?.status || null} />
								)}
							</div>
						</CCardHeader>
						<CCardBody>
							<CRow>
								<CCol md={6}>
									<dl>
										<dt>{_t('transaction.transaction-id')}:</dt>
										<dd>{id}</dd>

										<dt>{_t('global.customer')}</dt>
										<dd>
											{transaction?.customer && (
												<Link to={`/customers/${transaction.customer.id}`}>{transaction.customer.name}</Link>
											)}
											{!transaction && '-'}
										</dd>

										<dt>{_t('global.type')}:</dt>
										<dd>{transaction?.type || ''}</dd>

										<dt>{_t('global.amount')}:</dt>
										<dd>{transaction && formatCurrency(transaction.amount, transaction.currency)}</dd>

										<dt>{_t('global.net-amount')} USD:</dt>
										<dd>{formatCurrency(transaction?.netAmountInUSD || 0)}</dd>

										<dt>{_t('transactions.ip-address')}:</dt>
										<dd>
											{transaction?.ipAddress}&nbsp;
											{getCountryIcon(transaction?.countryCode || '')}
										</dd>
									</dl>
								</CCol>
								<CCol md={6}>
									<dl>
										<dt>{_t('global.method')}:</dt>
										<dd>{transaction?.method || 'Internal'}</dd>

										<dt>{_t('global.provider')}:</dt>
										<dd>{transaction?.provider || 'Internal'}</dd>

										<dt>{_t('global.provider-reference')}:</dt>
										<dd>{transaction?.providerRef || _t('global.no-value')}</dd>

										<dt>{_t('transactions.wallet')}:</dt>
										<dd>{transaction?.wallet}</dd>

										<dt>{_t('global.comment')}:</dt>
										<dd>{transaction?.comment}</dd>

										<dt>{_t('global.admin')}:</dt>
										<dd>
											<Link to={`/customers/${admin?.id}`}>{admin ? admin.name : null}</Link>
										</dd>

										{loadTransactionQuery.data?.withdrawalReview && (
											<>
												<dt>{_t('transaction.admin-ip-address')}:</dt>
												<dd>
													{transaction?.withdrawalReview.reviewerIp}&nbsp;
													{getCountryIcon(transaction.reviewerCountryCode || '')}
												</dd>
											</>
										)}
									</dl>
								</CCol>
							</CRow>
						</CCardBody>
					</CCard>
				</CCol>
				<CCol lg={6}>
					<CCard>
						<CCardHeader>
							<CCardTitle>{_t('transactions.exchange-rate-details')}</CCardTitle>
						</CCardHeader>
						<CCardBody>
							<CRow>
								<CCol md={6}>
									<dl>
										<dt>{_t('transactions.conversion.from')}:</dt>
										<dd>{transaction?.conversionFrom || '-'}</dd>

										<dt>{_t('transactions.conversion.from-amount')}:</dt>
										<dd>
											{transaction?.conversionFrom !== null
												? formatCurrency(transaction?.conversionFromAmount, transaction?.conversionFrom)
												: '-'}
										</dd>

										<dt>{_t('transactions.conversion.rate')}:</dt>
										<dd>{Number(transaction?.conversionRate).toFixed(2)}</dd>

										<dt>{_t('transactions.conversion.date')}:</dt>
										<dd>{transaction?.conversionDate ? formatDateTime(transaction?.conversionDate) : '-'}</dd>
									</dl>
								</CCol>
								<CCol md={6}>
									<dl>
										<dt>{_t('transactions.conversion.to')}:</dt>
										<dd>{transaction.conversionTo || '-'}</dd>

										<dt>{_t('transactions.conversion.to-amount')}:</dt>
										<dd>
											{transaction?.conversionTo !== null
												? formatCurrency(transaction?.conversionToAmount, transaction?.conversionTo)
												: '-'}
										</dd>

										<dt>{_t('transactions.conversion.source')}:</dt>
										<dd>{transaction?.conversionSource || '-'}</dd>
									</dl>
								</CCol>
							</CRow>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>

			{selectedWithdrawal && (
				<UpdateWithdrawalStateModal
					show={Boolean(selectedWithdrawal)}
					onClose={onWithdrawalChangeStateClose}
					actionType={selectedWithdrawal?.tableActionType}
					withdrawal={selectedWithdrawal.withdrawal}
				/>
			)}
		</PageLayout>
	);
};

export default TransactionPage;
