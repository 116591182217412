import React, { useEffect, useMemo, useState } from 'react';
import _t from 'counterpart';
import PageLayout from '../../components/PageLayout';
import PaginationTable, { ISorter } from '../../components/PaginationTable';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
import { CRow, CCol, CCard, CCardBody, CCardHeader, CButton, CLink, CBadge, CSwitch } from '@coreui/react';
import { useQuery, useQueryClient } from 'react-query';
import { fetchWallets } from '../../services/BackendService';
import { useAppDispatch, useAppSelector, useLiveAccountsUpdates } from '../../helpers/customHooks';
import WalletsFilter from './WalletsFilter';
import SearchFilter from '../../components/SearchFilter';
import { formatCurrency, getFiltersCount } from '../../helpers';
import { shallowEqual } from 'react-redux';
import CIcon from '@coreui/icons-react';
import CustomerWalletPopup from '../customers/CustomerWalletPopup';
import { clearWalletsFilters } from '../../actions';
import { IWalletWithBalance } from './types';
import Error from '../../components/Error';

const WalletsPage = () => {
	const [editWallet, setEditWallet] = useState<IWalletWithBalance | null>(null);
	const [showFilters, setShowFilters] = useState<boolean>(false);
	const [page, setPage] = useQueryParam('page', NumberParam);
	const [filter, setFilter] = useQueryParam('filter', StringParam);
	const [orderBy = 'createdAt|DESC', setOrderBy] = useQueryParam('orderBy', StringParam);
	const [onlyShowWalletsWithOpenPositions, setOnlyShowWalletsWithOpenPositions] = useState<boolean>(false);

	const dispatch = useAppDispatch();

	const walletFilters = useAppSelector((state) => state.filters.walletsFilters, shallowEqual);
	const queryClient = useQueryClient();

	const walletsItemsPerPageFromLocalStorage = Number(localStorage.getItem('items_per_page_wallets_table') ?? 10);
	const [limit, setLimit] = useState<number>(walletsItemsPerPageFromLocalStorage);
	const offset = Number(page) > 0 ? Number(page) * limit - limit : 0;
	useLiveAccountsUpdates();

	const walletFiltersDTO = {
		currencies: walletFilters.currency.map((c) => c.value),
		leverageFrom: walletFilters.leverageFrom,
		leverageTo: walletFilters.leverageTo,
		isDemo: walletFilters.type?.value,
		groups: walletFilters.groups.map((r) => r.value),
	};

	const loadWalletsQuery = useQuery(
		['all-wallets', filter, page, orderBy, walletFiltersDTO, onlyShowWalletsWithOpenPositions],
		() => fetchWallets(limit, offset, filter || '', walletFiltersDTO, onlyShowWalletsWithOpenPositions)
	);

	const onLimitChanged = async (limit: number) => {
		setLimit(limit);
		localStorage.setItem('items_per_page_wallets_table', limit.toString());
		await queryClient.invalidateQueries('wallets');
		loadWalletsQuery.refetch();
	};

	const resetFilters = () => {
		setOnlyShowWalletsWithOpenPositions(false);
		dispatch(clearWalletsFilters());
	};

	const onPageChanged = (page: number) => {
		setPage(page, 'replaceIn');
	};

	const onSorterChanged = ({ column, asc }: ISorter) => {
		const sortBy = `${column}|${asc ? 'ASC' : 'DESC'}`;
		if (sortBy !== orderBy) {
			setOrderBy(sortBy, 'replaceIn');
			setPage(1, 'replaceIn');
		}
	};

	const searchTableData = (value: string) => {
		if (value !== filter) {
			setPage(1, 'replaceIn');
			setFilter(value, 'replaceIn');
		}
	};

	const toggleEnabled = () => {
		setPage(1, 'replaceIn');
		const enabled = !onlyShowWalletsWithOpenPositions;
		setOnlyShowWalletsWithOpenPositions(enabled);
	};

	const onEditModalClosed = () => {
		setEditWallet(null);
		loadWalletsQuery.refetch();
	};

	const filtersCount = getFiltersCount(walletFilters);

	const [column, isAsc] = orderBy!.split('|');
	const asc = isAsc === 'ASC';

	const onErrorRetry = () => {
		loadWalletsQuery.refetch();
	};

	const fixedCellStyle = useMemo(() => {
		return { minWidth: '130px' };
	}, []);

	const tableFields = useMemo(
		() => [
			{ key: 'username', label: _t('wallets.accountId'), sorter: false },
			{ key: 'customer', label: _t('wallets.customer-name'), sorter: false },
			{ key: 'balance', label: _t('customer.wallets.balance'), sorter: false, _style: fixedCellStyle },
			{ key: 'profitLoss', label: _t('wallet.profit-loss'), sorter: false, _style: fixedCellStyle },
			{ key: 'marginLevel', label: _t('wallet.margin-level'), sorter: false },
			{ key: 'margin', label: _t('wallet.margin'), sorter: false, _style: fixedCellStyle },
			{ key: 'marginFree', label: _t('wallet.free-margin'), sorter: false, _style: fixedCellStyle },
			{ key: 'equity', label: _t('wallet.equity'), sorter: false, _style: fixedCellStyle },
			{ key: 'currency', label: _t('wallets.currency'), sorter: false },
			{ key: 'leverage', label: _t('wallets.leverage'), sorter: false },
			{ key: 'group', label: _t('wallets.group'), sorter: false },
			{ key: 'isDemo', label: _t('wallet-modal.is-demo'), sorter: false },
			{ key: 'actions', label: _t('global.actions'), sorter: false },
		],
		[fixedCellStyle]
	);

	const marginLevel = (equity: string | null, margin: string) => {
		if (equity === null) {
			return null;
		}
		if (Number(margin) === 0) {
			return 0;
		}
		return Number(equity) / Number(margin);
	};

	const scopedSlots = useMemo(
		() => ({
			username: (wallet: IWalletWithBalance) => (
				<td>
					<CLink href={`/wallets/${wallet.id}`}>{wallet.username}</CLink>
				</td>
			),
			isDemo: (wallet: IWalletWithBalance) => (
				<td>
					<CBadge color={wallet.isDemo ? 'danger' : 'success'}>
						{wallet.isDemo ? _t('global.demo') : _t('global.real')}
					</CBadge>
				</td>
			),
			balance: (wallet: IWalletWithBalance) => <td>{formatCurrency(wallet.balance, wallet.currency)}</td>,
			marginLevel: (wallet: IWalletWithBalance) => <td>{marginLevel(wallet.equity, wallet.margin)?.toFixed(2)}</td>,
			margin: ({ margin, currency }: IWalletWithBalance) => <td>{formatCurrency(margin, currency)}</td>,
			marginFree: ({ marginFree, currency }: IWalletWithBalance) => <td>{formatCurrency(marginFree, currency)}</td>,
			equity: ({ equity, currency }: IWalletWithBalance) => <td>{formatCurrency(equity, currency)}</td>,
			profitLoss: (wallet: IWalletWithBalance) => (
				<td>{wallet.profitLoss === undefined ? '-' : formatCurrency(wallet.profitLoss, wallet.currency)}</td>
			),
			customer: (wallet: IWalletWithBalance) => (
				<td>
					<CLink href={`../customers/${wallet.customer?.id}`}>{wallet.customer?.name}</CLink>
				</td>
			),
			group: (wallet: IWalletWithBalance) => <td>{wallet.group?.name}</td>,
			actions: (wallet: IWalletWithBalance) => (
				<td>
					<CButton
						className="mr-2"
						color="primary"
						type="button"
						onClick={() => setEditWallet(wallet)}
						title="Edit instrument"
					>
						<CIcon name="cil-pencil" size="sm" />
					</CButton>
				</td>
			),
		}),
		[]
	);

	if (loadWalletsQuery.isError) {
		return <Error onRetry={onErrorRetry} />;
	}

	return (
		<PageLayout title={_t.translate('wallets.title')}>
			<CRow>
				<CCol>
					<CCard>
						<CCardHeader className="pb-0">
							<div>
								<div className="filters-header">
									<SearchFilter onSearch={searchTableData} />
									<div className="filters-header-inline w-100">
										<div className="filters-header-buttons float-left">
											<CButton className="filters-header-buttons-active" onClick={() => setShowFilters(!showFilters)}>
												<div className="d-flex justify-content-center align-items-center">
													<span>{_t('global.filters')}</span>
													{filtersCount > 0 && (
														<div className="filters-header-buttons-active-inner">{filtersCount}</div>
													)}
													<div className={`filters-header-buttons-active-image ${showFilters ? 'rotated' : ''}`} />
												</div>
											</CButton>
											<CButton onClick={resetFilters} className="filters-header-buttons-reset">
												{_t('action.reset')}
											</CButton>
										</div>
									</div>
								</div>
								<WalletsFilter show={showFilters} />
							</div>
							<div style={{ marginTop: '1%' }}>
								<CSwitch color="primary" checked={onlyShowWalletsWithOpenPositions} onChange={toggleEnabled} />
								<span className="ml-2">{_t.translate('customer.wallets.only-show-wallets-with-open-positions')}</span>
							</div>
						</CCardHeader>
						<CCardBody>
							<PaginationTable
								tableFields={tableFields}
								scopedSlots={scopedSlots}
								data={loadWalletsQuery.data?.wallets || []}
								onPageChanged={onPageChanged}
								onSorterChanged={onSorterChanged}
								sorter={{ column, asc }}
								loading={loadWalletsQuery.isLoading}
								pages={loadWalletsQuery.data?.pages || 0}
								activePage={page || 1}
								pagination
								onLimitChanged={onLimitChanged}
								itemsPerPage={limit}
							/>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>

			{editWallet && <CustomerWalletPopup wallet={editWallet} onClose={onEditModalClosed} />}
		</PageLayout>
	);
};

export default React.memo(WalletsPage);
