import React, { useCallback, useEffect, useState } from 'react';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Content from '../components/Content';
import { requestLoadUser } from '../actions';
import { useAppDispatch, useAppSelector } from '../helpers/customHooks';
import { isImpersonatedSelector } from '../selectors';
import wsService from '../services/WebsocketService';
import toast from 'react-hot-toast';
import infoIcon from '../icons/info.svg';
import CIcon from '@coreui/icons-react';
import _t from 'counterpart';
import { CButton } from '@coreui/react';
import { useHistory } from 'react-router-dom';

export const PUSH_NOTIFICATION_ID = 'push-notification';

const MainPage = () => {
	const [toastId, setToastId] = useState<string>('');
	const isImpersonated = useAppSelector(isImpersonatedSelector);
	const isPushNotifcationToastDismissed = useAppSelector((state) => state.user.dismissPushNotificationToast);
	const dispatch = useAppDispatch();
	const history = useHistory();

	const handleProfileClick = useCallback(() => {
		toast.dismiss(toastId);
		history.push('/profile');
	}, [toastId, history]);

	useEffect(() => {
		const checkSubscriptionAndNotify = async () => {
			if ('serviceWorker' in navigator && 'Notification' in window) {
				const registration = await navigator.serviceWorker.register('/push-worker.js');
				await navigator.serviceWorker.ready;

				const subscription = await registration.pushManager.getSubscription();
				if (!subscription) {
					const toastId = toast(
						<div className="d-flex align-items-center">
							<CIcon src={infoIcon} />
							<div className="d-flex flex-column ml-2 toast-message">
								<span className="p-0 m-0">{_t('global.notifications')}</span>
								<div>{_t('profile.go-to-profile')}</div>
							</div>
							<div>
								<CButton size="md" color="dark" onClick={handleProfileClick}>
									{_t('profile.profile')}
								</CButton>
							</div>
						</div>,
						{
							duration: 15000,
							id: PUSH_NOTIFICATION_ID,
						}
					);
					setToastId(toastId);
				}
			}
		};

		if (history.location.pathname !== '/profile' && !isPushNotifcationToastDismissed) {
			checkSubscriptionAndNotify();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		wsService.connect();
	}, []);

	useEffect(() => {
		if (!isImpersonated) {
			dispatch(requestLoadUser());
		}
	}, [dispatch, isImpersonated]);

	return (
		<div className="c-app-c-default-layout">
			<Sidebar />
			<div className="c-wrapper">
				<Header />
				<div className="c-body">
					<Content />
				</div>
			</div>
		</div>
	);
};

export default MainPage;
