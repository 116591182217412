import React, { PureComponent } from 'react';
import { XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, AreaChart, Area } from 'recharts';
import { timeFormat } from 'd3-time-format';
import { IDashboardChartDatum, DashboardPeriods } from '../../pages/dashboard/types';

interface ChartProps {
	data: Array<IDashboardChartDatum>;
	name: string;
	type?: DashboardPeriods;
}

export default class Chart extends PureComponent<ChartProps> {
	tickFormatter(tick: string) {
		return timeFormat('%m/%d')(new Date(tick));
	}

	tickFormatterHour(tick: string) {
		return timeFormat('%I %p')(new Date(tick.toLocaleString()));
	}

	renderTime(timePoint: string): string {
		if (this.shouldRenderHours()) return this.tickFormatterHour(timePoint);

		return this.tickFormatter(timePoint.toLocaleString());
	}

	shouldRenderHours() {
		return this.props.type && this.props.type === DashboardPeriods.Last24Hours;
	}

	render() {
		const formatter = (value: any, name: any, props: any) => {
			return [props.value, props.name];
		};

		return (
			<ResponsiveContainer>
				<AreaChart
					data={this.props.data}
					margin={{
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
					}}
				>
					<defs>
						<linearGradient id="area" x1="0" y1="0" x2="0" y2="1">
							<stop offset="5%" stopColor="var(--primary)" stopOpacity={0.8} />
							<stop offset="95%" stopColor="var(--primary)" stopOpacity={0} />
						</linearGradient>
					</defs>

					<CartesianGrid strokeDasharray="3 3" />
					<XAxis
						dataKey="date"
						interval="preserveStartEnd"
						tickFormatter={this.shouldRenderHours() ? this.tickFormatterHour : this.tickFormatter}
					/>
					<YAxis domain={['auto', (dataMax: number) => dataMax * 1.1]} />
					<Tooltip formatter={formatter} labelFormatter={(value) => this.renderTime(value.toString())} />
					<Legend />
					<Area
						type="monotone"
						dataKey="value"
						fill="url(#area)"
						stroke="var(--primary)"
						strokeWidth="2"
						activeDot={{ r: 4 }}
						name={this.props.name}
					/>
				</AreaChart>
			</ResponsiveContainer>
		);
	}
}
