import React, { useState } from 'react';
import _t from 'counterpart';
import { useQuery } from 'react-query';
import { CForm, CRow, CCol, CFormGroup, CInput } from '@coreui/react';
import CustomSelect, { ISelectOption } from '../../components/CustomSelect';
import { useAppDispatch, useAppSelector } from '../../helpers/customHooks';
import { IWalletsFilters } from '../../reducers/filtersReducer';
import { setWalletsFilters } from '../../actions';
import { shallowEqual } from 'react-redux';
import { currencies, types } from './types';
import { loadGroups } from '../../services/BackendService';
import { extractErrorMessage } from '../../helpers';
import FilterInputHeader from '../../components/FilterInputHeader';
import toast from 'react-hot-toast';
import { IGroup } from '../groups/types';

interface IProps {
	show: boolean;
}

const WalletsFilter = ({ show }: IProps) => {
	const [groups, setGroups] = useState<Array<ISelectOption>>([]);

	const dispatch = useAppDispatch();
	const activeFilters = useAppSelector((state) => state.filters.walletsFilters, shallowEqual);

	useQuery(['filter-groups'], () => loadGroups(), {
		onSuccess: (data: Array<IGroup>) => {
			const groupsToOptions = data.map((g: IGroup) => {
				return { label: g.name, value: g.id.toString() };
			});
			setGroups(groupsToOptions);
		},
		onError: (err: any) => {
			const msg = extractErrorMessage(err);
			toast.error(msg);
		},
	});

	const setFilters = (filters: Partial<IWalletsFilters>) => {
		dispatch(setWalletsFilters(filters));
	};

	const resetSelectedCurrencies = () => {
		setFilters({
			currency: [],
		});
	};

	const handleCurrenciesChanged = (currencies: any) => {
		setFilters({
			currency: currencies,
		});
	};

	const handleFrom = (e: any) => {
		const amount = e.target.value;

		setFilters({
			leverageFrom: amount || undefined,
		});
	};

	const handleTo = (e: any) => {
		const amount = e.target.value;

		setFilters({
			leverageTo: amount || undefined,
		});
	};

	const resetAmountRange = () => {
		setFilters({
			leverageFrom: '',
			leverageTo: '',
		});
	};

	const handleTypeChanged = (type: any) => {
		setFilters({ type });
	};

	const resetType = () => {
		setFilters({ type: null });
	};

	const handleGroupsChanged = (groups: any) => {
		setFilters({ groups });
	};

	const resetGroup = () => {
		setFilters({
			groups: [],
		});
	};

	return (
		<CForm className={`w-100 filters ${!show ? 'd-none' : ''}`}>
			<CRow>
				<CCol md={4}>
					<CFormGroup>
						<FilterInputHeader labelText={_t('wallets.filters.currencies')} resetFilter={resetSelectedCurrencies} />
						<CustomSelect
							value={activeFilters.currency ?? null}
							options={currencies}
							onChange={handleCurrenciesChanged}
							isMulti
							isClearable={false}
							whiteBackground
						/>
					</CFormGroup>
				</CCol>
				<CCol md={4}>
					<CFormGroup>
						<FilterInputHeader labelText={_t('wallets.leverage')} resetFilter={resetAmountRange} />
						<div className="d-flex justify-content-between">
							<CInput
								type="number"
								value={activeFilters.leverageFrom}
								onChange={handleFrom}
								className="mr-1"
								placeholder={_t('global.from')}
							/>
							<CInput
								type="number"
								value={activeFilters.leverageTo}
								onChange={handleTo}
								className="ml-1"
								placeholder={_t('global.to')}
							/>
						</div>
					</CFormGroup>
				</CCol>
				<CCol md={4}>
					<CFormGroup>
						<FilterInputHeader labelText={_t('global.type')} resetFilter={resetType} />
						<CustomSelect
							value={activeFilters.type}
							options={types}
							onChange={handleTypeChanged}
							isClearable={false}
							whiteBackground
						/>
					</CFormGroup>
				</CCol>
				<CCol md={4}>
					<CFormGroup>
						<FilterInputHeader labelText={_t('wallets.group')} resetFilter={resetGroup} />
						<CustomSelect
							value={activeFilters.groups}
							options={groups}
							onChange={handleGroupsChanged}
							isMulti
							whiteBackground
						/>
					</CFormGroup>
				</CCol>
			</CRow>
		</CForm>
	);
};

export default React.memo(WalletsFilter);
