import CIcon from '@coreui/icons-react';
import { CBadge } from '@coreui/react';
import _t from 'counterpart';
import React, { useCallback } from 'react';
import ActivityDot from '../../components/ActivityDot';
import PaginationTable, { ISorter } from '../../components/PaginationTable';
import UserStatusBadge from '../../components/UserStatusBadge';
import { formatCurrency, formatDateTime, getIconNameForCountry } from '../../helpers';
import CustomerWarning from './CustomerWarning';
import { MessageType } from './types';
import { useAppSelector } from '../../helpers/customHooks';
import SalesStatusBadge from '../../components/SalesStatusBadge';

export interface ITableColumnVisibilityItem {
	key: string;
	options?: {
		enabled?: boolean;
		sorter?: boolean;
		disabled?: boolean;
	};
}

export const customersTableColumns = [
	{ key: 'name', options: { sorter: false, disabled: true } },
	{ key: 'accountId', options: { sorter: false } },
	{ key: 'activity', options: { sorter: false } },
	{ key: 'country', options: { sorter: false } },
	{ key: 'totalCoinsEarned' },
	{ key: 'createdAt' },
	{ key: 'source' },
	{ key: 'depositTotal' },
	{ key: 'positionsCount' },
	{ key: 'totalReferrals' },
	{ key: 'salesStatusName', options: { sorter: false } },
	{ key: 'conversionAgentName', options: { sorter: false } },
	{ key: 'retentionAgentName', options: { sorter: false } },
	{ key: 'status', options: { sorter: false } },
	{ key: 'lastTradingActivityDate' },
	{ key: 'referrer', options: { sorter: false } },
	{ key: 'lastDepositDate' },
	{ key: 'lastCommentDate' },
	{ key: 'lastContactDate' },
	{ key: 'commissionsEarned' },
];

interface IProps {
	data: Array<any>;
	onPageChanged: (page: number) => void;
	onSorterChanged: (values: ISorter) => void;
	onLimitChanged?: (value: number) => void;
	sorter: ISorter;
	isLoading: boolean;
	pages: number;
	activePage: number;
	onRowClicked: (value: any) => void;
	showFilter: boolean;
	onSearchValueChanged?: (value: string) => void;
	filterValue?: string;
	itemsPerPage: number;
	columnsAreHideable?: boolean;
}

const CustomersTable = (props: IProps) => {
	const {
		data,
		onPageChanged,
		onSorterChanged,
		sorter,
		isLoading,
		activePage,
		pages,
		onRowClicked,
		showFilter,
		onSearchValueChanged,
		filterValue,
		itemsPerPage,
		onLimitChanged,
		columnsAreHideable = false,
	} = props;
	const { column, asc } = sorter;

	const customerTableVisibleColumns = useAppSelector((state) => state.user.visibleTableColumns['CUSTOMERS_TABLE']);

	const createTableField = useCallback(
		(key: string, options?: any) => {
			const label = _t(`customers.columns.${key}`);
			if (!columnsAreHideable) return { key, label, ...options };
			if (!options?.disabled && !customerTableVisibleColumns.includes(key)) return undefined;
			return { key, label, ...options };
		},
		[customerTableVisibleColumns, columnsAreHideable]
	);

	const tableFields = customersTableColumns
		.map((item) => createTableField(item.key, item.options))
		.filter((field) => field !== undefined);

	return (
		<PaginationTable
			tableFields={tableFields}
			scopedSlots={{
				name: (user: any) => (
					<td className="text-nowrap">
						{user.name}
						<CustomerWarning riskStatus={user.riskStatus} />
					</td>
				),
				activity: ({ lastActive }: any) => (
					<td>
						<ActivityDot lastActivity={lastActive} />
					</td>
				),
				country: (user: any) => (
					<td>
						{getIconNameForCountry(user.country.toLowerCase()) ? (
							<CIcon name={getIconNameForCountry(user.country.toLowerCase())!} size="xl" title={user.countryName} />
						) : (
							user.country
						)}
					</td>
				),
				coins: (user: any) => <td>{user.coins}</td>,
				createdAt: (user: any) => <td className="text-nowrap">{formatDateTime(user.createdAt)}</td>,
				source: (user: any) => <td>{user.source || '-'}</td>,
				depositTotal: (user: any) => <td>{formatCurrency(user.depositTotal)}</td>,
				totalReferrals: (user: any) => <td>{user.totalReferrals}</td>,
				status: (user: any) => (
					<td className="table-badges">
						<UserStatusBadge status={user.status} />
						{user.availableContactMethods.includes(MessageType.Push) && (
							<CBadge className="ml-1" color="success">
								{_t('action.push')}
							</CBadge>
						)}
						{user.appInstalledAt && (
							<CBadge className="ml-1" color="success" title={formatDateTime(user.appInstalledAt)}>
								{_t('action.downloaded')}
							</CBadge>
						)}
					</td>
				),
				salesStatusName: ({ salesStatusName }: any) => (
					<td>{salesStatusName ? <SalesStatusBadge status={salesStatusName} /> : '-'}</td>
				),
				conversionAgentName: ({ conversionAgent }: any) => <td>{conversionAgent ? conversionAgent.name : '-'}</td>,
				retentionAgentName: ({ retentionAgent }: any) => <td>{retentionAgent ? retentionAgent.name : '-'}</td>,
				lastTradingActivityDate: (user: any) => (
					<td className="text-nowrap">
						{user.lastTradingActivityDate !== null ? formatDateTime(user.lastTradingActivityDate) : '-'}
					</td>
				),
				referrer: (user: any) => <td>{user.referrer ? user.referrer : '-'}</td>,
				lastDepositDate: (user: any) => (
					<td className="text-nowrap">{user.lastDepositDate !== null ? formatDateTime(user.lastDepositDate) : '-'}</td>
				),
				lastCommentDate: (user: any) => (
					<td className="text-nowrap">{user.lastCommentDate !== null ? formatDateTime(user.lastCommentDate) : '-'}</td>
				),
				lastContactDate: (user: any) => (
					<td className="text-nowrap">{user.lastContactDate !== null ? formatDateTime(user.lastContactDate) : '-'}</td>
				),
				commissionsEarned: (user: any) => (
					<td>{user.commissionsEarned ? formatCurrency(user.commissionsEarned) : '-'}</td>
				),
			}}
			data={data}
			onPageChanged={onPageChanged}
			onSorterChanged={onSorterChanged}
			sorter={{ column, asc }}
			loading={isLoading}
			pages={pages || 0}
			activePage={activePage}
			onRowClicked={onRowClicked}
			clickableRows
			pagination
			showFilter={showFilter}
			search={filterValue || ''}
			onSearchValueChanged={onSearchValueChanged}
			itemsPerPage={itemsPerPage}
			onLimitChanged={onLimitChanged}
		/>
	);
};

CustomersTable.defaultProps = {
	onSearchValueChanged: null,
	filterValue: '',
};

export default CustomersTable;
