import React from 'react';
import { CRow, CCard, CCol, CCardBody, CLink } from '@coreui/react';
import _t from 'counterpart';
import { useQuery } from 'react-query';
import { formatCurrency, extractErrorMessage } from '../../helpers';
import { loadDashboardStats } from '../../services/BackendService';
import { IDashboardState } from './types';
import SingleStat from '../../components/SingleStat';
import Loading from '../../components/Loading';
import Chart from '../../components/dashboard/Chart';
import DoubleChart from '../../components/dashboard/DoubleChart';
import DepositsChart from '../../components/dashboard/DepositsChart';
import PageLayout from '../../components/PageLayout';
import Error from '../../components/Error';
import toast from 'react-hot-toast';

const DashboardPage = () => {
	const end = new Date();
	const start = new Date(end.getTime() - 30 * 24 * 60 * 60 * 1000);

	const dashboardQuery = useQuery<IDashboardState | null>(['dashboard'], () => loadDashboardStats(start, end), {
		onError: (error: any) => {
			toast.error(extractErrorMessage(error));
		},
		retry: false,
	});

	const onErrorRetry = () => {
		dashboardQuery.refetch();
	};

	if (dashboardQuery.isLoading || dashboardQuery.isIdle) {
		return <Loading />;
	}

	if (dashboardQuery.isError) {
		return <Error onRetry={onErrorRetry} />;
	}

	const {
		totalRegistrations,
		totalCoins,
		totalDeposits,
		totalDownloads,
		pendingPayouts,
		depositsChart,
		registrationsChart,
		loginsChart,
		tradesPositionsChart,
		totalPushAccepted,
	} = dashboardQuery.data!;

	return (
		<PageLayout title={_t.translate('global.overview')}>
			<CRow>
				<CCol xs="6" sm="6" lg="4" xl="4">
					<SingleStat title={_t('dashboard.customers')} color="blue" value={totalRegistrations || 0} />
				</CCol>
				<CCol xs="6" sm="6" lg="4" xl="4">
					<SingleStat title={_t('dashboard.total-coins')} color="green" value={totalCoins || 0} />
				</CCol>
				<CCol xs="6" sm="6" lg="4" xl="4">
					<SingleStat
						title={_t('dashboard.deposits')}
						color="yellow"
						value={formatCurrency(totalDeposits || 0, 'USD')}
					/>
				</CCol>
				<CCol xs="6" sm="6" lg="4" xl="4">
					<SingleStat title={_t('dashboard.installations')} color="red" value={totalDownloads || 0} />
				</CCol>
				<CCol xs="6" sm="6" lg="4" xl="4">
					<SingleStat title={_t('dashboard.enabled-push')} color="blue" value={totalPushAccepted || 0} />
				</CCol>
				<CCol xs="6" sm="6" lg="4" xl="4">
					<CLink href="/payout-requests" className="text-decoration-none">
						<SingleStat title={_t('dashboard.pending-payout-requests')} color="orange" value={pendingPayouts} />
					</CLink>
				</CCol>
			</CRow>

			<CRow>
				<CCol sm={12} md={6}>
					<CCard className="card--overview pl-0">
						<CCardBody>
							<DoubleChart
								data={registrationsChart || []}
								leftDataKey="registrations"
								rightDataKey="installations"
								leftTranslationKey="registration-chart.new-registrations"
								rightTranslationKey="registration-chart.installations"
							/>
						</CCardBody>
					</CCard>
				</CCol>
				<CCol sm={12} md={6}>
					<CCard className="card--overview pl-0">
						<CCardBody>
							<DepositsChart data={depositsChart || []} name={_t('dashboard.deposits')} />
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
			<CRow>
				<CCol sm={12} md={6}>
					<CCard className="card--overview pl-0">
						<CCardBody style={{ height: 465 }}>
							<Chart data={loginsChart || []} name={_t('dashboard.logins')} />
						</CCardBody>
					</CCard>
				</CCol>
				<CCol sm={12} md={6}>
					<CCard className="card--overview pl-0">
						<CCardBody>
							<DoubleChart
								data={tradesPositionsChart || []}
								leftDataKey="position"
								rightDataKey="trade"
								leftTranslationKey="dashboard.positions"
								rightTranslationKey="dashboard.trading-users"
								showRightYAxis
							/>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
		</PageLayout>
	);
};

export default DashboardPage;
