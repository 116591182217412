import React, { useState, useMemo } from 'react';
import _t from 'counterpart';
import { CRow, CCol, CCard, CCardHeader, CButton, CCardBody, CBadge } from '@coreui/react';
import { useQueryParam, StringParam, NumberParam } from 'use-query-params';
import { useAppDispatch, useAppSelector } from '../../helpers/customHooks';
import { shallowEqual } from 'react-redux';
import { stringify } from 'qs';
import { fetchAffiliates } from '../../services/BackendService';
import { useQuery, useQueryClient } from 'react-query';
import CIcon from '@coreui/icons-react';
import { getFiltersCount, getIconNameForCountry, formatDateTime, formatCurrency } from '../../helpers';
import { useHistory } from 'react-router-dom';
import { MessageType } from './types';
import { clearAffiliateFilters } from '../../actions';
import AffiliatesFilter from './AffiliatesFilter';
import PageLayout from '../../components/PageLayout';
import SearchFilter from '../../components/SearchFilter';
import PaginationTable, { ISorter } from '../../components/PaginationTable';
import UserStatusBadge from '../../components/UserStatusBadge';
import ExportXLSXButton from '../../components/ExportXLSXButton';
import Error from '../../components/Error';

const AffiliatesPage = () => {
	const createTableField = (key: string, options?: any) => {
		const label = _t(`customers.columns.${key}`);
		return { key, label, ...options };
	};

	const [showFilters, setShowFilters] = useState<boolean>(false);
	const [page, setPage] = useQueryParam('page', NumberParam);
	const [search = '', setSearch] = useQueryParam('filter', StringParam);
	const [orderBy = 'createdAt|DESC', setOrderBy] = useQueryParam('orderBy', StringParam);

	const affiliateFilters = useAppSelector((state) => state.filters.affiliateFilters, shallowEqual);
	const selectedStatuses = affiliateFilters.statuses.map((status) => status.value);
	const country = affiliateFilters.country?.value || null;
	const queryClient = useQueryClient();

	const affiliatesItemsPerPageFromLocalStorage = Number(localStorage.getItem('items_per_page_affiliates_table') ?? 10);
	const [limit, setLimit] = useState<number>(affiliatesItemsPerPageFromLocalStorage);
	const offset = Number(page) > 0 ? Number(page) * limit - limit : 0;

	const affiliatesQuery = useQuery(['affiliates', search, page, orderBy, affiliateFilters], () =>
		fetchAffiliates({ search, country, selectedStatuses, limit, offset, sortBy: orderBy })
	);

	const history = useHistory();

	const dispatch = useAppDispatch();
	const token = useAppSelector((state: any) => state.user.token);

	const filtersCount = getFiltersCount(affiliateFilters);

	const onPageChanged = (page: number) => {
		setPage(page, 'replaceIn');
	};

	const searchTableData = (value: string) => {
		if (value !== search) {
			setPage(1, 'replaceIn');
			setSearch(value, 'replaceIn');
		}
	};

	const onLimitChanged = async (limit: number) => {
		setLimit(limit);
		localStorage.setItem('items_per_page_affiliates_table', limit.toString());
		await queryClient.invalidateQueries('affiliates');
		affiliatesQuery.refetch();
	};

	const onSorterChanged = ({ column, asc }: ISorter) => {
		const sortBy = `${column}|${asc ? 'ASC' : 'DESC'}`;
		if (sortBy !== orderBy) {
			setOrderBy(sortBy, 'replaceIn');
			setPage(1, 'replaceIn');
		}
	};

	const onFilterChanged = () => {
		setPage(1, 'replaceIn');
	};

	const onRowClicked = (user: any) => {
		history.push(`/affiliates/${user.id}`);
	};

	const resetFilters = () => {
		dispatch(clearAffiliateFilters());
	};

	const [column, isAsc] = orderBy!.split('|');
	const asc = isAsc === 'ASC';

	const tableFields = useMemo(
		() => [
			createTableField('name'),
			createTableField('country', { sorter: false }),
			createTableField('createdAt'),
			createTableField('depositTotal'),
			createTableField('totalReferrals'),
			createTableField('affiliateBalance'),
			createTableField('commissionsEarned'),
			createTableField('status', { sorter: false }),
		],
		[]
	);

	const scopedSlots = useMemo(
		() => ({
			name: (user: any) => <td className="text-nowrap">{user.name}</td>,
			country: (user: any) => (
				<td>
					{getIconNameForCountry(user.country.toLowerCase()) ? (
						<CIcon name={getIconNameForCountry(user.country.toLowerCase())!} size="xl" title={user.countryName} />
					) : (
						user.country
					)}
				</td>
			),
			createdAt: (user: any) => <td className="text-nowrap">{formatDateTime(user.createdAt)}</td>,
			depositTotal: (user: any) => <td>{formatCurrency(user.depositTotal)}</td>,
			affiliateBalance: (user: any) => <td>{formatCurrency(user.affiliateBalance)}</td>,
			commissionsEarned: (user: any) => <td>{formatCurrency(user.commissionsEarned)}</td>,
			status: (user: any) => (
				<td className="table-badges">
					<UserStatusBadge status={user.status} />
					{user.availableContactMethods.includes(MessageType.Push) && (
						<CBadge className="ml-1" color="success">
							{_t('action.push')}
						</CBadge>
					)}
					{user.appInstalledAt && (
						<CBadge className="ml-1" color="success" title={formatDateTime(user.appInstalledAt)}>
							{_t('action.downloaded')}
						</CBadge>
					)}
				</td>
			),
		}),
		[]
	);

	const getExportLink = (): string => {
		const params: any = { token, selectedStatuses, country };

		return `${process.env.REACT_APP_API_ROOT}/api/admin/customers/export/affiliates/xlsx?${stringify(params)}`;
	};

	let recordCount = 0;
	if (affiliatesQuery.data) {
		const { pages, customers } = affiliatesQuery.data;
		recordCount = Number(pages) + Number(customers.length);
	}

	const onErrorRetry = () => {
		affiliatesQuery.refetch();
	};

	if (affiliatesQuery.isError) {
		return <Error onRetry={onErrorRetry} />;
	}

	return (
		<PageLayout title={_t.translate('affiliates.title')}>
			<CRow>
				<CCol>
					<CCard style={{ overflow: 'visible' }}>
						<CCardHeader className="pb-0">
							<div>
								<div className="filters-header">
									<SearchFilter onSearch={searchTableData} />
									<div className="filters-header-inline w-100">
										<div className="filters-header-buttons float-left">
											<CButton className="filters-header-buttons-active" onClick={() => setShowFilters(!showFilters)}>
												<div className="d-flex justify-content-center align-items-center">
													<span>{_t('global.filters')}</span>
													{filtersCount > 0 && (
														<div className="filters-header-buttons-active-inner">{filtersCount}</div>
													)}
													<div className={`filters-header-buttons-active-image ${showFilters ? 'rotated' : ''}`} />
												</div>
											</CButton>
											<CButton onClick={resetFilters} className="filters-header-buttons-reset">
												{_t('action.reset')}
											</CButton>
										</div>
										<div className="float-right">
											{recordCount !== 0 && (
												<ExportXLSXButton downloadLink={getExportLink()} defaultFilename="affiliates.xlsx" />
											)}
										</div>
									</div>
								</div>
								<AffiliatesFilter show={showFilters} onFilterChanged={onFilterChanged} />
							</div>
						</CCardHeader>
						<CCardBody>
							<PaginationTable
								tableFields={tableFields}
								scopedSlots={scopedSlots}
								data={affiliatesQuery.data?.customers || []}
								onPageChanged={onPageChanged}
								onSorterChanged={onSorterChanged}
								sorter={{ column, asc }}
								loading={affiliatesQuery.isLoading}
								pages={affiliatesQuery.data?.pages || 0}
								activePage={page || 1}
								onRowClicked={onRowClicked}
								clickableRows
								pagination
								itemsPerPage={limit}
								onLimitChanged={onLimitChanged}
							/>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
		</PageLayout>
	);
};

export default React.memo(AffiliatesPage);
