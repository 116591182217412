import React, { useState } from 'react';
import { CButton, CSpinner } from '@coreui/react';
import classNames from 'classnames';
import * as Sentry from '@sentry/react';
import exportIcon from '../icons/export.svg';

interface IExportBtnProps {
	defaultFilename: string;
	downloadLink: string;
	disabled?: boolean;
	className?: string;
}

const ExportXLSXButton = ({ downloadLink, disabled, defaultFilename, className }: IExportBtnProps) => {
	const [loading, setLoading] = useState(false);

	const handleDownload = async () => {
		try {
			setLoading(true);
			const a = document.createElement('a');
			document.body.appendChild(a);
			a.download = defaultFilename;
			a.href = downloadLink;
			a.click();
			a.remove();
		} catch (e) {
			Sentry.captureException(e);
		} finally {
			setLoading(false);
		}
	};

	return (
		<CButton
			className={classNames('header-button-export', className)}
			onClick={handleDownload}
			disabled={disabled || loading}
		>
			{!loading && <img src={exportIcon} alt="excel-export" />}
			{loading && <CSpinner color="light" size="sm" />}
		</CButton>
	);
};

ExportXLSXButton.defaultProps = {
	className: undefined,
	disabled: false,
};

export default ExportXLSXButton;
