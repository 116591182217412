import React, { PureComponent } from 'react';
import { XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar } from 'recharts';
import { timeFormat } from 'd3-time-format';
import { formatCurrency } from '../../helpers';
import { IDashboardChartDatum, DashboardPeriods } from '../../pages/dashboard/types';

interface ChartProps {
	data: Array<IDashboardChartDatum>;
	name: string;
	type?: DashboardPeriods;
	formatter?: (value: any, name: any, props: any) => Array<any>;
}

export default class DepositsChart extends PureComponent<ChartProps> {
	tickFormatter(tick: string) {
		return timeFormat('%m/%d')(new Date(tick));
	}

	tickFormatterHour(tick: string) {
		return timeFormat('%I %p')(new Date(tick.toLocaleString()));
	}

	renderTime(timePoint: string): string {
		if (this.shouldRenderHours()) return this.tickFormatterHour(timePoint);

		return this.tickFormatter(timePoint.toLocaleString());
	}

	shouldRenderHours() {
		return this.props.type && this.props.type === DashboardPeriods.Last24Hours;
	}

	render() {
		const formatter = (value: any, name: any) => {
			return [formatCurrency(value, 'USD'), name];
		};

		return (
			<ResponsiveContainer width="100%" height={400}>
				<BarChart
					data={this.props.data}
					margin={{
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
					}}
				>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis
						dataKey="date"
						interval="preserveStartEnd"
						tickFormatter={this.shouldRenderHours() ? this.tickFormatterHour : this.tickFormatter}
					/>

					<YAxis tickFormatter={(v) => formatCurrency(v, 'USD', 0)} />

					<Tooltip formatter={formatter} labelFormatter={(value) => this.renderTime(value.toString())} />

					<Legend />

					<Bar dataKey="value" fill="var(--primary)" name={this.props.name} />
				</BarChart>
			</ResponsiveContainer>
		);
	}
}
