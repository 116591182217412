import React, { useState, useMemo, useCallback } from 'react';
import { shallowEqual } from 'react-redux';
import { CCard, CCardBody, CRow, CCol, CCardHeader, CButton } from '@coreui/react';
import _t from 'counterpart';
import { stringify } from 'qs';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
import { loadOrdersQuery } from '../../services/BackendService';
import { useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { IOrder, IOrderQueryParams } from './types';
import { formatDateTime, getFiltersCount } from '../../helpers';
import { useAppDispatch, useAppSelector } from '../../helpers/customHooks';
import PaginationTable from '../../components/PaginationTable';
import SearchFilter from '../../components/SearchFilter';
import Loading from '../../components/Loading';
import OrdersFilter from './OrdersFilters';
import OrderStatusBadge from './OrderStatusBadge';
import ExportXLSXButton from '../../components/ExportXLSXButton';
import PageLayout from '../../components/PageLayout';
import Error from '../../components/Error';
import TradingSideBadge from '../../components/TradingSideBadge';
import { clearOrdersFilters } from '../../actions';
import Avatar from '../../components/avatar/Avatar';

export interface IAnalyticsRecord {
	downloads: string;
	level: number;
	registrations: string;
}

const OrdersPage = () => {
	const [showFilters, setShowFilters] = useState<boolean>(false);
	const [cacheOverrideDate] = useState<number>(new Date().getTime());

	const [page, setPage] = useQueryParam('page', NumberParam);
	const [filter = '', setFilter] = useQueryParam('filter', StringParam);

	const history = useHistory();
	const queryClient = useQueryClient();
	const dispatch = useAppDispatch();

	const ordersFilters = useAppSelector((state) => state.filters.ordersFilters, shallowEqual);
	const statuses = ordersFilters.statuses?.map((s: any) => s.value);
	const symbols = ordersFilters.symbol?.map((s: any) => s.value);
	const types = ordersFilters.orderTypes?.map((s: any) => s.value);
	const ordersItemsPerPageFromLocalStorage = Number(localStorage.getItem('items_per_page_orders_table') ?? 10);
	const [limit, setLimit] = useState<number>(ordersItemsPerPageFromLocalStorage);
	const offset = Number(page) > 0 ? Number(page) * limit - limit : 0;

	const token = useAppSelector((state: any) => state.user.token);

	const orderParams: IOrderQueryParams = useMemo(() => {
		return {
			limit,
			offset,
			search: filter!,
			statuses: statuses || [],
			accountId: ordersFilters.accountId,
			symbols: symbols || [],
			types,
		};
	}, [limit, offset, filter, statuses, ordersFilters.accountId, symbols, types]);

	const ordersQuery = useQuery(['orders', orderParams], () => loadOrdersQuery(orderParams));

	const onLimitChanged = async (limit: number) => {
		setLimit(limit);
		localStorage.setItem('items_per_page_orders_table', limit.toString());
		await queryClient.invalidateQueries('orders');
		ordersQuery.refetch();
	};

	const tableFields = useMemo(
		() => [
			{ key: 'id', label: 'ID', sorter: false },
			{ key: 'externalId', label: _t('orders.external-id'), sorter: false },
			{ key: 'positionId', label: _t('orders.position-id'), sorter: false },
			{ key: 'type', label: _t('positions.type'), sorter: false },
			{ key: 'dates', label: _t('positions.orders.dates'), sorter: false },
			{ key: 'quantity', label: _t('positions.orders.quantity'), sorter: false },
			{ key: 'prices', label: _t('positions.orders.prices'), sorter: false },
			{ key: 'side', label: _t('orders.side'), sorter: false },
			{ key: 'symbol', label: _t('orders.symbol'), sorter: true },
			{ key: 'status', label: _t('positions.status'), sorter: false },
		],
		[]
	);

	const scopedSlots = useMemo(
		() => ({
			externalId: ({ externalId }: IOrder) => <td>{externalId || '-'}</td>,
			positionId: ({ positionId }: IOrder) => <td>{positionId || '-'}</td>,
			dates: ({ executedAt, placedAt }: IOrder) => (
				<td>
					<div>{placedAt ? formatDateTime(placedAt) : '-'}</div>
					<div>{executedAt ? formatDateTime(executedAt) : '-'}</div>
				</td>
			),
			quantity: ({ quantity, filledQuantity }: IOrder) => (
				<td>
					<div>{quantity || '-'}</div>
					<div>{filledQuantity || '-'}</div>
				</td>
			),
			prices: ({ averagePrice, requestPrice }: IOrder) => (
				<td>
					<div>{requestPrice || '-'}</div>
					<div>{averagePrice || '-'}</div>
				</td>
			),

			symbol: ({ symbol, iconUrl }: IOrder) => (
				<td>
					<Avatar imgUrl={iconUrl ? `${iconUrl}?${cacheOverrideDate}` : null} name={symbol} />
					<span>{symbol}</span>
				</td>
			),
			side: ({ side }: IOrder) => (
				<td>
					<TradingSideBadge side={side} />
				</td>
			),
			status: ({ status, rejectReason }: IOrder) => (
				<td>
					<div>
						<OrderStatusBadge status={status} />
					</div>
					{rejectReason && <div>{rejectReason}</div>}
				</td>
			),
		}),
		[]
	);

	const rowClicked = useCallback(
		(order: IOrder) => {
			const { id } = order;
			history.push(`/orders/${id}`);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[history.push]
	);

	const searchTableData = (value: string) => {
		if (value !== filter) {
			setPage(1, 'replaceIn');
			setFilter(value, 'replaceIn');
		}
	};

	const onFilterChanged = () => {
		setPage(1, 'replaceIn');
	};

	const resetFilters = () => {
		dispatch(clearOrdersFilters());
	};

	const getExportLink = useCallback((): string => {
		const params: any = { ...orderParams, token };

		return `${process.env.REACT_APP_API_ROOT}/api/admin/orders/export/xlsx?${stringify(params)}`;
	}, [token, orderParams]);

	const onErrorRetry = () => {
		ordersQuery.refetch();
	};

	if (ordersQuery.isIdle) {
		return <Loading />;
	}

	if (ordersQuery.isError) {
		return <Error onRetry={onErrorRetry} />;
	}

	const count = ordersQuery.data?.count || 0;
	const pages = Math.ceil(count / limit);

	const filtersCount = getFiltersCount(ordersFilters);

	return (
		<PageLayout title={_t.translate('sidebar.orders')}>
			<CRow>
				<CCol>
					<CCard style={{ overflow: 'visible' }}>
						<CCardHeader className="pb-0">
							<div>
								<div className="filters-header">
									<SearchFilter onSearch={searchTableData} />
									<div className="filters-header-inline w-100">
										<div className="filters-header-buttons float-left">
											<CButton className="filters-header-buttons-active" onClick={() => setShowFilters(!showFilters)}>
												<div className="d-flex justify-content-center align-items-center">
													<span>{_t('global.filters')}</span>
													{filtersCount > 0 && (
														<div className="filters-header-buttons-active-inner">{filtersCount}</div>
													)}
													<div className={`filters-header-buttons-active-image ${showFilters ? 'rotated' : ''}`} />
												</div>
											</CButton>
											<CButton onClick={resetFilters} className="filters-header-buttons-reset">
												{_t('action.reset')}
											</CButton>
										</div>
										<div className="float-right">
											{count !== 0 && <ExportXLSXButton downloadLink={getExportLink()} defaultFilename="orders.xlsx" />}
										</div>
									</div>
								</div>
								<OrdersFilter show={showFilters} onFilterChanged={onFilterChanged} />
							</div>
						</CCardHeader>
						<CCardBody>
							<PaginationTable
								tableFields={tableFields}
								scopedSlots={scopedSlots}
								data={ordersQuery.data?.orders || []}
								loading={ordersQuery.isLoading}
								pages={pages}
								pagination
								onRowClicked={rowClicked}
								clickableRows
								onPageChanged={setPage}
								activePage={page || 1}
								itemsPerPage={limit}
								onLimitChanged={onLimitChanged}
							/>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
		</PageLayout>
	);
};

export default OrdersPage;
