import React from 'react';
import { CCard } from '@coreui/react';

interface SingleStatProps {
	title: string;
	value: string | number;
	color: string;
}

const SingleStat = (props: SingleStatProps) => {
	const { value, title, color } = props;

	return (
		<CCard className={`card--stat card--${color}`}>
			<div className="card__holder">
				<div className="card__stats">
					<span className="card__value">{value.toLocaleString()}</span>
					<span className="card__title">{title}</span>
				</div>
			</div>
		</CCard>
	);
};

export default SingleStat;
