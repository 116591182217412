import React, { useState } from 'react';
import moment from 'moment';
import {
	CBadge,
	CCard,
	CCardBody,
	CCardHeader,
	CCardTitle,
	CCol,
	CDropdown,
	CDropdownItem,
	CDropdownMenu,
	CDropdownToggle,
	CNav,
	CNavItem,
	CNavLink,
	CProgress,
	CProgressBar,
	CRow,
	CTabContent,
	CTabPane,
	CTabs,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import _t from 'counterpart';
import {
	capitalize,
	extractErrorMessage,
	formatCurrency,
	formatDateTime,
	getIconNameForCountry,
	getIconNameForLanguage,
	isSet,
} from '../../../helpers';
import { loadCustomer, fetchCustomerCommunication, canUserBeCalled } from '../../../services/BackendService';
import { useAppDispatch, useAppSelector } from '../../../helpers/customHooks';
import { ICustomerModalsShow, initialModalsState } from '../types';
import { shallowEqual } from 'react-redux';
import { requestImpersonation } from '../../../actions';
import countries from '../../../vendor/ftt_countries.json';
import AdminCustomerWalletsTable from '../CustomerWalletsTable';
import CustomerTransactionsTable from '../CustomerTransactionsTable';
import CustomerModals from '../CustomerModals';
import Loading from '../../../components/Loading';
import UserStatusBadge from '../../../components/UserStatusBadge';
import Detail from '../../../components/micro/Detail';
import CustomerPositionsTable from '../CustomerPositionsTable';
import { KYCTab } from '../CustomerKYCTab';
import PageLayout from '../../../components/PageLayout';
import BookmarkCustomer from './BookmarkCustomer';
import ReferralsLink from './ReferralsLink';
import CustomerCommentsList from '../CustomerCommentsList';
import CustomerRoleBadge from './CustomerRoleBadge';
import Error from '../../../components/Error';
import { Permission } from '../../../reducers/userReducer';
import CommunicationsTable from './CommunicationsTable';
import LoyaltyLevelDetails from './LoyaltyLevelDetails';
import ActivityDot from '../../../components/ActivityDot';
import CustomerWarning from '../CustomerWarning';
import RiskEventsTable from './RiskEventsTable';
import SalesStatusBadge from '../../../components/SalesStatusBadge';
import toast from 'react-hot-toast';

const CustomerPage = () => {
	const [modalsVisibility, setModalsVisibility] = useState<ICustomerModalsShow>(initialModalsState);
	const [bookmarked, setBookmarked] = useState<boolean>(false);
	const [communicationsPage, setCommunicationsPage] = useState<number>(1);
	const communicationsLimit = 10;
	const communicationsOffset =
		Number(communicationsPage) > 0 ? Number(communicationsPage) * communicationsLimit - communicationsLimit : 0;

	const userState = useAppSelector((state) => state.user, shallowEqual);

	const params = useParams();
	const dispatch = useAppDispatch();
	const history = useHistory();

	const showError = (errorMessage: string) => {
		const error = extractErrorMessage(errorMessage);
		toast.error(error);
	};

	const { customerId } = params as any;
	const loadCustomerQuery = useQuery(['customer', customerId], () => loadCustomer(customerId), {
		onError: (e: any) => {
			showError(e);
		},
		onSuccess: (data) => {
			setBookmarked(data.isBookmarked);
		},
		refetchInterval: 60000,
	});

	const { isLoading, refetch, isError } = loadCustomerQuery;

	const canBeCalledQuery = useQuery(['can-user-be-called', customerId], () => canUserBeCalled(customerId), {
		onError: (e: any) => {
			showError(e);
		},
	});

	const communicationsQuery = useQuery(
		['customer-communications', loadCustomerQuery.data?.id, communicationsOffset],
		() => fetchCustomerCommunication(loadCustomerQuery.data?.id || '', communicationsLimit, communicationsOffset),
		{
			enabled: isSet(loadCustomerQuery.data?.id),
		}
	);

	const showTargetModal = (e: any) => {
		setModalsVisibility({ ...modalsVisibility, [e.target.name]: true });
	};

	const setTargetModalVisibility = (modalsVisibility: ICustomerModalsShow, refetchCustomer?: boolean) => {
		setModalsVisibility(modalsVisibility);
		if (refetchCustomer) {
			refetch();
		}
	};

	const showAffiliateModal = () => {
		document.querySelector('body')?.classList.add('modal-open');
		setModalsVisibility({ ...modalsVisibility, affiliatesModalShow: true });
	};

	const showMessageModal = () => {
		setModalsVisibility({ ...modalsVisibility, messageModalShow: true });
	};

	const showSalesPersonModal = () => {
		document.querySelector('body')?.classList.add('modal-open');
		setModalsVisibility({ ...modalsVisibility, salesPersonModalShow: true });
	};

	const showCallCustomerModal = () => {
		setModalsVisibility({ ...modalsVisibility, callCustomerModalShow: true });
	};

	const showEmailCustomerModal = () => {
		setModalsVisibility({ ...modalsVisibility, emailCustomerModalShow: true });
	};

	const impersonate = () => {
		dispatch(requestImpersonation(customerId));
	};

	const onErrorRetry = () => {
		refetch();
	};

	const onEditClicked = () => {
		history.push(`/customers/edit/${customer!.id}`);
	};

	const fields = React.useMemo(
		() => [
			{ key: 'date', label: capitalize(_t('global.date')), sorter: false },
			{ key: 'from', label: capitalize(_t('global.from')), sorter: false },
			{ key: 'type', label: capitalize(_t('global.type')), sorter: false },
			{ key: 'detail', label: capitalize(_t('global.details')), sorter: false },
			{ key: 'actions', label: capitalize(_t('global.actions')), sorter: false },
		],
		[]
	);

	if (isLoading && !loadCustomerQuery.data) {
		return <Loading />;
	}

	if (isError) {
		return <Error onRetry={onErrorRetry} />;
	}

	const locale = _t.getLocale();

	const { data: customer } = loadCustomerQuery;
	const kycApproved = customer?.kycStatus === 'APPROVED';

	const {
		id,
		roles,
		status,
		confirmedAt,
		accountId,
		referrer,
		affiliateId,
		email,
		mobilenumber,
		communicationLanguage,
		country,
		appInstalledAt,
		lastLoginDate,
		commissionScheme,
		totalCoinsEarned,
		lastTradingActivityDate,
		totalVolumeTraded,
		percent,
		tradeVolumeOpen,
		totalLogins,
		depositTotal,
		documentCount,
		totalReferrals,
		tradeVolumeLastWeek,
		conversionAgent,
		retentionAgent,
		salesDesk,
		salesStatusName,
		hasFTD,
		lastActive,
		riskStatus,
		withdrawalTotal,
		source,
		commissionsGranted,
		commissionsEarned,
		terminationRequestedAt,
	} = customer!;

	const hasReferrer = Boolean(referrer) && referrer.trim() !== '';
	const canImpersonate = userState.permissions?.includes(Permission.IMPERSONATE);

	const onBookmarkedMutated = (bookmarked: boolean) => {
		setBookmarked(bookmarked);
		refetch();
	};

	const communicationPages =
		communicationsQuery.data?.count === undefined
			? 0
			: Math.ceil(Number(communicationsQuery.data?.count) / communicationsLimit);

	const linkToReferralTree = () => {
		history.push(`/referral_tree?id=${customerId}`);
	};

	const linkToOnboardingSteps = () => {
		history.push(`/customers/onboarding_steps/${customerId}`);
	};

	return (
		<PageLayout
			title={customer?.name || ''}
			titleAppend={
				<>
					<div>
						{userState.id !== customer?.id && (
							<BookmarkCustomer id={id} bookmarked={bookmarked} onChanged={onBookmarkedMutated} />
						)}
						<div className="float-left customer-activity-container ml-2 d-flex align-items-center">
							<ActivityDot lastActivity={lastActive} />
						</div>
						<a href="#risk" className="float-left customer-activity-container d-flex align-items-center">
							<CustomerWarning riskStatus={riskStatus} />
						</a>
					</div>
					<div className="d-flex justify-content-between float-right mb-2">
						<div className="d-flex flex-nowrap flex-row-reverse">
							<CDropdown className="float-right">
								<CDropdownToggle caret color="primary">
									{_t('customer.customer-options')}
								</CDropdownToggle>
								<CDropdownMenu>
									{userState.permissions.includes(Permission.MANAGE_CUSTOMERS) && (
										<>
											<CDropdownItem onClick={onEditClicked}>{_t('action.edit')}</CDropdownItem>
											<CDropdownItem onClick={showTargetModal} name="updatePasswordShow">
												{_t('customer.update-password')}
											</CDropdownItem>
											<CDropdownItem onClick={showAffiliateModal} name="affiliatesModalShow">
												{_t('customer.assign-referrer')}
											</CDropdownItem>
											<CDropdownItem onClick={showSalesPersonModal} name="salesPersonModalShow">
												{_t('customer.assign-sales-person')}
											</CDropdownItem>
											{['ACTIVE', 'PENDING'].includes(status) && (
												<CDropdownItem name="confirmBarShow" onClick={showTargetModal}>
													{_t('customer.disable-user')}
												</CDropdownItem>
											)}
											{['DISABLED'].includes(status) && (
												<CDropdownItem name="confirmBarShow" onClick={showTargetModal}>
													{_t('customer.enable-user')}
												</CDropdownItem>
											)}
											{!confirmedAt && (
												<CDropdownItem onClick={showTargetModal} name="confirmEmailShow">
													{_t('customer.confirm-email')}
												</CDropdownItem>
											)}
											<CDropdownItem onClick={showTargetModal} name="changeCommissionSchemeShow">
												{_t('customer.change-commission-scheme')}
											</CDropdownItem>
											{riskStatus === 'REVIEW' && (
												<CDropdownItem onClick={showTargetModal} name="removeRiskStatusShow">
													{_t('customer.remove-risk-status')}
												</CDropdownItem>
											)}
										</>
									)}
									{canImpersonate && <CDropdownItem onClick={impersonate}>{_t('customer.impersonate')}</CDropdownItem>}
									{userState.permissions.includes(Permission.MANAGE_SALES_STATUS_CUSTOMER) && (
										<CDropdownItem onClick={showTargetModal} name="assignSalesStatusShow">
											{_t('sales-status.assign-sales-status')}
										</CDropdownItem>
									)}
									{userState.permissions.includes(Permission.VIEW_REFERRAL_TREE) && (
										<CDropdownItem onClick={linkToReferralTree}>{_t('customers.referral-tree-view')}</CDropdownItem>
									)}
									{userState.permissions.includes(Permission.MANAGE_CUSTOMER_ONBOARDING_STEPS) && (
										<CDropdownItem onClick={linkToOnboardingSteps}>{_t('customer.onboarding-steps')}</CDropdownItem>
									)}
								</CDropdownMenu>
							</CDropdown>

							{userState.permissions.includes(Permission.MANAGE_CUSTOMERS) && (
								<CDropdown className="float-right mr-2">
									<CDropdownToggle caret color="primary">
										{_t('customer.customer-communication')}
									</CDropdownToggle>
									<CDropdownMenu>
										{userState.permissions.includes(Permission.SEND_MESSAGES) && (
											<CDropdownItem onClick={showEmailCustomerModal}>{_t('global.email')}</CDropdownItem>
										)}
										{canBeCalledQuery.data && (
											<CDropdownItem onClick={showCallCustomerModal} name="callModalShow">
												{_t('action.call')}
											</CDropdownItem>
										)}
										{userState.permissions.includes(Permission.MANAGE_MESSAGES) && (
											<CDropdownItem onClick={showMessageModal} name="messageModalShow">
												{_t('global.message')}
											</CDropdownItem>
										)}
									</CDropdownMenu>
								</CDropdown>
							)}
						</div>
					</div>
				</>
			}
		>
			<CRow>
				<CCol md={6}>
					<CCard>
						<CCardHeader>
							<CCardTitle className="float-left">{_t('global.info')}</CCardTitle>
							<div className="float-right">
								<UserStatusBadge status={status} />
							</div>
						</CCardHeader>
						<CCardBody>
							<CRow>
								<CCol md={6}>
									<dl>
										<Detail title={_t('customer.account-id')}>{accountId}</Detail>
										<Detail title={_t('global.email')}>
											<CNavLink style={{ padding: 0 }} href={`mailto:${email}`}>
												{email}
											</CNavLink>
										</Detail>
										<Detail title={_t('global.mobile-number')}>
											<span>{mobilenumber || '-'}</span>
										</Detail>
										<Detail title={_t('customer.roles')}>
											<span>
												{roles.map((r: any) => {
													return <CustomerRoleBadge key={r} role={r} />;
												})}
											</span>
										</Detail>
										{userState.permissions.includes(Permission.VIEW_CUSTOMERS) && (
											<Detail title={_t('customer.conversion-agent')}>
												{conversionAgent ? (
													<CNavLink style={{ padding: 0 }} href={`/customers/${conversionAgent.id}`}>
														{conversionAgent.name}
													</CNavLink>
												) : (
													'-'
												)}
											</Detail>
										)}
										{userState.permissions.includes(Permission.VIEW_CUSTOMERS) && (
											<Detail title={_t('customer.retention-agent')}>
												{retentionAgent ? (
													<CNavLink style={{ padding: 0 }} href={`/customers/${retentionAgent.id}`}>
														{retentionAgent.name}
													</CNavLink>
												) : (
													'-'
												)}
											</Detail>
										)}
										<Detail title={_t('customer.deposited')}>
											{hasFTD ? (
												<CBadge color="success">{_t('global.yes')}</CBadge>
											) : (
												<CBadge color="danger">{_t('global.no')}</CBadge>
											)}
										</Detail>
										<Detail title={_t('customer.termination-requested-at')}>
											{terminationRequestedAt ? formatDateTime(terminationRequestedAt) : '-'}
										</Detail>
									</dl>
								</CCol>
								<CCol md={6}>
									<dl>
										<Detail title={_t('global.country')}>
											{getIconNameForCountry(country.toLowerCase()) && (
												<CIcon name={getIconNameForCountry(country.toLowerCase())!} size="xl" title={country} />
											)}
											&nbsp;
											{countries.find((i) => i['alpha-2'] === country)?.name}
										</Detail>
										<Detail title={_t('customer.communication-language')}>
											{getIconNameForLanguage(communicationLanguage) && (
												<CIcon
													name={getIconNameForLanguage(communicationLanguage)!}
													size="xl"
													title={communicationLanguage}
												/>
											)}
											&nbsp;
											{_t(`languages.${communicationLanguage.toLowerCase()}`)}
										</Detail>
										<Detail title={_t('customer.app-installed')}>
											{appInstalledAt ? (
												formatDateTime(appInstalledAt)
											) : (
												<CBadge color="danger">{_t('customer.not-yet-installed')}</CBadge>
											)}
										</Detail>
										<Detail title={_t('customer.email-confirmed-at')}>
											{confirmedAt ? (
												formatDateTime(confirmedAt)
											) : (
												<CBadge color="danger">{_t('customer.not-confirmed-yet')}</CBadge>
											)}
										</Detail>
										<Detail title={_t('customer.last-login')}>
											{lastLoginDate ? (
												<span title={formatDateTime(lastLoginDate)}>
													{moment(lastLoginDate).locale(locale).fromNow()}
												</span>
											) : (
												<CBadge color="danger">{_t('customer.not-logged-in-yet')}</CBadge>
											)}
										</Detail>
										<Detail title={_t('customer.commission-scheme')}>
											<span>{commissionScheme?.name ?? '-'}</span>
										</Detail>
										<Detail title={_t('sales-desks.sales-desk')}>
											{salesDesk ? <a href={`/sales-desks/${salesDesk.id}`}>{salesDesk.name}</a> : '-'}
										</Detail>
										<Detail title={_t('sales-status.sales-status')}>
											{salesStatusName ? <SalesStatusBadge status={salesStatusName} /> : '-'}
										</Detail>
									</dl>
								</CCol>
							</CRow>
						</CCardBody>
					</CCard>

					<CCard>
						<CCardHeader>
							<CCardTitle>{_t('customer.acquisition')}</CCardTitle>
						</CCardHeader>
						<CCardBody>
							<CRow>
								<CCol md={6}>
									<dl>
										{userState.permissions.includes(Permission.MANAGE_CUSTOMERS) && (
											<Detail title={_t('customer.referrer')}>
												{hasReferrer ? <a href={`/customers/${affiliateId}`}>{referrer}</a> : '-'}
											</Detail>
										)}
									</dl>
								</CCol>
								<CCol md={6}>
									<dl>
										<Detail title={_t('customer.source')}>{source || '-'}</Detail>
									</dl>
								</CCol>
							</CRow>
						</CCardBody>
					</CCard>

					<CCard>
						<CCardHeader>
							<CCardTitle>{_t('global.stats')}</CCardTitle>
						</CCardHeader>
						<CCardBody>
							<CRow>
								<CCol md={6}>
									<dl>
										<Detail title={_t('customer.total-coins-earned')}>{totalCoinsEarned}</Detail>
										<Detail title={_t('customers.columns.lastTradingActivityDate')}>
											{lastTradingActivityDate !== null
												? formatDateTime(lastTradingActivityDate)
												: _t('customers.never')}
										</Detail>
										<Detail title={_t('customer.total-volume-traded')}>
											{totalVolumeTraded}&nbsp;{_t('customer.lots')}
										</Detail>
										<Detail title={_t('global.progress')}>
											<CProgress>
												<CProgressBar
													value={Math.min(percent || 0, 100)}
													max={100}
													showPercentage
													showValue
													precision={0}
												/>
											</CProgress>
										</Detail>
										<Detail title={_t('customer.open-positions-volume')}>
											{tradeVolumeOpen}
											&nbsp;
											{_t('customer.lots')}
										</Detail>
										<Detail title={_t('customer.commissions-granted')}>
											{commissionsGranted ? formatCurrency(commissionsGranted) : 0}
										</Detail>
									</dl>
								</CCol>
								<CCol md={6}>
									<dl>
										<Detail title={_t('customer.total-logins')}>{totalLogins}</Detail>
										<Detail title={_t('customer.deposit-total')}>{formatCurrency(depositTotal)}</Detail>
										<Detail title={_t('customer.withdrawal-total')}>{formatCurrency(withdrawalTotal)}</Detail>
										<Detail title={_t('customer.referrals-counter')}>
											<ReferralsLink customerId={id} totalReferrals={totalReferrals} />
										</Detail>
										<Detail title={_t('customer.week-volume-traded')}>
											{tradeVolumeLastWeek}
											&nbsp;
											{_t('customer.lots')}
										</Detail>
										<Detail title={_t('customers.columns.commissionsEarned')}>
											{commissionsEarned ? formatCurrency(commissionsEarned) : 0}
										</Detail>
									</dl>
								</CCol>
							</CRow>
						</CCardBody>
					</CCard>
				</CCol>
				<CCol md={6}>
					<CTabs activeTab="notes">
						<CNav variant="tabs">
							<CNavItem>
								<CNavLink data-tab="notes">{_t('customer.notes')}</CNavLink>
							</CNavItem>
							<CNavItem>
								<CNavLink data-tab="kyc">
									{_t('customer.kyc')}
									&nbsp;
									{kycApproved ? <CIcon className="text-success" name="cil-check" /> : `(${documentCount})`}
								</CNavLink>
							</CNavItem>
						</CNav>
						<CCard className="card--with-top-tabs">
							<CCardBody>
								<CTabContent>
									<CTabPane data-tab="notes">
										<CustomerCommentsList />
									</CTabPane>
									<CTabPane data-tab="kyc" className="kyc-document-container">
										<KYCTab customer={customer} onUpdated={refetch} />
									</CTabPane>
								</CTabContent>
							</CCardBody>
						</CCard>
					</CTabs>
					<LoyaltyLevelDetails customerId={customerId} />
				</CCol>
			</CRow>

			<AdminCustomerWalletsTable customerId={id} />
			<CustomerTransactionsTable customerId={id} />
			<CustomerPositionsTable />
			<CommunicationsTable
				isLoading={communicationsQuery.isLoading}
				fields={fields}
				data={communicationsQuery.data?.communications || []}
				pages={communicationPages}
				page={communicationsPage}
				onPageChanged={setCommunicationsPage}
			/>
			<div id="risk">
				<RiskEventsTable customerId={id} />
			</div>

			<CustomerModals
				modalsVisibility={modalsVisibility}
				id={id}
				setTargetModalVisibility={setTargetModalVisibility}
				customer={customer}
			/>
		</PageLayout>
	);
};

export default CustomerPage;
