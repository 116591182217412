import React, { PureComponent } from 'react';
import { XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, AreaChart, Area } from 'recharts';
import { timeFormat } from 'd3-time-format';
import _t from 'counterpart';
import { IDashboardChartDatum, DashboardPeriods } from '../../pages/dashboard/types';

interface ChartProps {
	data: Array<IDashboardChartDatum>;
	period?: DashboardPeriods;
	leftDataKey: string;
	rightDataKey: string;
	leftTranslationKey: string;
	rightTranslationKey: string;
	showRightYAxis?: boolean;
}

export default class DoubleChart extends PureComponent<ChartProps> {
	tickFormatter(tick: string) {
		return timeFormat('%m/%d')(new Date(tick));
	}

	tickFormatterHour(tick: string) {
		return timeFormat('%I %p')(new Date(tick.toLocaleString()));
	}

	renderTime(timePoint: string): string {
		if (this.shouldRenderHours()) return this.tickFormatterHour(timePoint);

		return this.tickFormatter(timePoint.toLocaleString());
	}

	shouldRenderHours() {
		const { period } = this.props;
		return period && period === DashboardPeriods.Last24Hours;
	}

	render() {
		const formatter = (value: any, name: any, props: any) => {
			return [props.value, props.name];
		};

		const { leftTranslationKey, rightTranslationKey, leftDataKey, rightDataKey, data, showRightYAxis } = this.props;

		return (
			<ResponsiveContainer width="100%" height={400}>
				<AreaChart
					data={data}
					margin={{
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
					}}
				>
					<defs>
						<linearGradient id="leftArea" x1="0" y1="0" x2="0" y2="1">
							<stop offset="5%" stopColor="var(--primary)" stopOpacity={0.8} />
							<stop offset="95%" stopColor="var(--primary)" stopOpacity={0} />
						</linearGradient>
						<linearGradient id="rightArea" x1="0" y1="0" x2="0" y2="1">
							<stop offset="5%" stopColor="var(--success)" stopOpacity={0.8} />
							<stop offset="95%" stopColor="var(--success)" stopOpacity={0} />
						</linearGradient>
					</defs>

					<CartesianGrid strokeDasharray="3 3" />
					<XAxis
						dataKey="date"
						interval="preserveStartEnd"
						tickFormatter={this.shouldRenderHours() ? this.tickFormatterHour : this.tickFormatter}
					/>
					<YAxis yAxisId="left" domain={['auto', (dataMax: number) => dataMax * 1.1]} />
					<YAxis
						yAxisId="right"
						domain={['auto', (dataMax: number) => dataMax * 1.1]}
						orientation="right"
						hide={!showRightYAxis}
					/>

					<Tooltip formatter={formatter} labelFormatter={(value) => this.renderTime(value.toString())} />
					<Legend iconType="plainline" />
					<Area
						type="monotone"
						dataKey={leftDataKey}
						fill="url(#leftArea)"
						stroke="var(--primary)"
						strokeWidth="2"
						dot={false}
						activeDot={{ r: 4 }}
						name={_t(leftTranslationKey)}
						yAxisId="left"
					/>
					<Area
						type="monotone"
						dataKey={rightDataKey}
						fill="url(#rightArea)"
						stroke="var(--success)"
						strokeWidth="2"
						dot={false}
						activeDot={{ r: 4 }}
						name={_t(rightTranslationKey)}
						yAxisId="right"
					/>
				</AreaChart>
			</ResponsiveContainer>
		);
	}
}
