import React, { useMemo, useState } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import {
	CCard,
	CCardBody,
	CRow,
	CCol,
	CCardHeader,
	CButton,
	CModal,
	CModalHeader,
	CModalTitle,
	CModalBody,
	CModalFooter,
	CCardTitle,
	CDataTable,
} from '@coreui/react';
import Loading from '../../components/Loading';
import { formatCurrency, formatDateTime, extractErrorMessage, isSet } from '../../helpers';
import _t from 'counterpart';
import PositionTypeBadge from '../../components/PositionTypeBadge';
import PositionStatusBadge from '../../components/PositionStatusBadge';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { loadPosition, closePosition, loadOrders } from '../../services/BackendService';
import { IPosition } from './position';
import { useLivePositionUpdates, useAppSelector } from '../../helpers/customHooks';
import OrderStatusBadge from '../orders/OrderStatusBadge';
import { IOrder } from '../orders/types';
import PageLayout from '../../components/PageLayout';
import { Permission } from '../../reducers/userReducer';
import Description from '../../components/Description';
import UpdatePositionModal from './UpdatePositionModal';
import toast from 'react-hot-toast';

const PositionPage = () => {
	const [closePositionModalShow, closePositionModalToggle] = useState<boolean>(false);
	const [editPositionShow, setEditPositionShow] = useState<IPosition | null>(null);

	const permissions = useAppSelector((state) => state.user.permissions);

	const params = useParams();
	const history = useHistory();

	const { id } = params as any;

	useLivePositionUpdates(id);

	const { data, isLoading } = useQuery<IPosition>(['position', id], () => loadPosition(id || ''), {
		onError: (e: any) => {
			const error = extractErrorMessage(e);
			toast.error(error);
		},
		retry: false,
	});

	const { data: orders, isLoading: isLoadingOrders } = useQuery(['position-order'], () => loadOrders(id || ''), {
		onError: (e: any) => {
			const error = extractErrorMessage(e);
			toast.error(error);
		},
		retry: false,
	});

	const queryClient = useQueryClient();

	const closePositionMutation = useMutation((id: string) => closePosition(id), {
		onSuccess: async () => {
			await queryClient.invalidateQueries('position');
			await queryClient.invalidateQueries('position-order');
			closePositionModalToggle(false);
		},
		onError: (e: any) => {
			const error = extractErrorMessage(e);
			toast.error(error);
		},
		retry: false,
	});

	const fields = useMemo(
		() => [
			{ key: 'id', label: 'ID' },
			{ key: 'externalId', label: _t('positions.orders.external-id') },
			{ key: 'type', label: _t('positions.type') },
			{ key: 'dates', label: _t('positions.orders.dates') },
			{ key: 'quantity', label: _t('positions.orders.quantity') },
			{ key: 'prices', label: _t('positions.orders.prices') },
			{ key: 'status', label: _t('positions.status') },
		],
		[]
	);

	const slots = useMemo(
		() => ({
			externalId: ({ externalId }: any) => <td>{externalId || '-'}</td>,
			dates: ({ executedAt, placedAt }: any) => (
				<td>
					<div>{placedAt || '-'}</div>
					<div>{executedAt || '-'}</div>
				</td>
			),
			quantity: ({ quantity, filledQuantity }: any) => (
				<td>
					<div>{quantity || '-'}</div>
					<div>{filledQuantity || '-'}</div>
				</td>
			),
			prices: ({ averagePrice, requestPrice }: any) => (
				<td>
					<div>{requestPrice || '-'}</div>
					<div>{averagePrice || '-'}</div>
				</td>
			),
			status: ({ status, rejectReason }: any) => (
				<td>
					<div>
						<OrderStatusBadge status={status} />
					</div>
					{rejectReason && <div>{rejectReason}</div>}
				</td>
			),
		}),
		[]
	);

	if (isLoading || !data) {
		return <Loading />;
	}

	const {
		accountId,
		size,
		closedPLInAccountCurrency,
		profitLoss,
		closeReason,
		customerId,
		invest,
		openedAt,
		platform,
		status,
		symbol,
		closePositionAfterSeconds,
		swap,
		takeProfit,
		stopLoss,
		commission,
		trailingStopLossPct,
	} = data!;

	const closePositionCall = () => {
		closePositionMutation.mutate(id);
	};

	const renderAccountId = (customerId: string | undefined, accountId: number) => {
		if (customerId) {
			return <Link to={`/customers/${customerId}`}>{accountId}</Link>;
		}
		return accountId;
	};

	const handleOrderRowClicked = (order: IOrder) => {
		history.push(`/orders/${order.id}`);
	};

	const showRetryClosingButton = status === 'in-closing' || status === 'error-on-closing';

	const durationLabel =
		closePositionAfterSeconds === null ? '∞' : `${closePositionAfterSeconds} ${_t('positions.seconds')}`;

	const pL = profitLoss ?? closedPLInAccountCurrency;

	const onEditClicked = () => {
		setEditPositionShow(data);
	};

	const onEditClose = async (refetch?: boolean) => {
		setEditPositionShow(null);
		if (refetch) {
			await queryClient.invalidateQueries('position');
			await queryClient.invalidateQueries('position-order');
		}
	};

	const hasEditPermissions = permissions?.includes(Permission.MANAGE_POSITIONS);
	const showClosePosition = data && data.status === 'open';
	const showEditPosition = data && (data.status === 'open' || data.status === 'closed' || data.status === 'new');

	return (
		<PageLayout
			title={_t.translate('positions.position.title') + ` #${id}`}
			titleAppend={
				<>
					{hasEditPermissions && (
						<div className="d-flex justify-content-between float-right mb-2">
							<div className="d-flex flex-nowrap flex-row-reverse">
								{showEditPosition && (
									<CButton color="primary" onClick={onEditClicked}>
										{_t('action.edit')}
									</CButton>
								)}
								{showClosePosition && (
									<CButton color="danger" className="mr-2" onClick={() => closePositionModalToggle(true)}>
										{_t('positions.position.close-position')}
									</CButton>
								)}
								{showRetryClosingButton && (
									<CButton color="danger" className="mr-2" onClick={() => closePositionModalToggle(true)}>
										{_t('positions.position.retry-closing')}
									</CButton>
								)}
							</div>
						</div>
					)}
				</>
			}
		>
			<CRow>
				<CCol md={12}>
					<CCard>
						<CCardBody>
							<CRow>
								<Description title={_t('positions.account-id')}>{renderAccountId(customerId, accountId)}</Description>
								<Description title={_t('positions.close-reason')}>
									{closeReason ? _t(`positions.close-reasons.${closeReason}`) : _t('positions.not-closed')}
								</Description>
							</CRow>
							<CRow>
								<Description title={_t('positions.opened-at')}>{formatDateTime(openedAt)}</Description>
								<Description title={_t('positions.status')}>
									<PositionStatusBadge position={data} />
								</Description>
							</CRow>
							<CRow>
								<Description title={_t('positions.type')}>
									<PositionTypeBadge position={data} />
								</Description>
								<Description title={_t('positions.platform')}>{platform}</Description>
							</CRow>
							<CRow>
								<Description title={_t('positions.symbol')}>{symbol}</Description>
								<Description title={_t('positions.stop-loss')}>{stopLoss ?? _t('positions.not-set')}</Description>
							</CRow>
							<CRow>
								<Description title={`${_t('positions.profit')} USD`}>
									{isSet(pL) ? formatCurrency(pL) : _t('positions.not-closed')}
								</Description>
								<Description title={_t('orders.trailing-stop-loss-pct')}>
									{trailingStopLossPct ? trailingStopLossPct + '%' : _t('positions.not-set')}
								</Description>
								<Description title={_t('positions.amount')}>{size}</Description>
								<Description title={_t('positions.take-profit')}>{takeProfit ?? _t('positions.not-set')}</Description>
							</CRow>
							<CRow>
								<Description title={_t('positions.duration')}>{durationLabel}</Description>
								<Description title={_t('positions.swap')}>{formatCurrency(swap === null ? 0 : swap)}</Description>

								<Description title={_t('positions.commission')}>
									{commission ? formatCurrency(commission) : '-'}
								</Description>
								<Description title={_t('positions.invest')}>{invest ?? _t('positions.not-set')}</Description>
							</CRow>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
			<CRow>
				<CCol md={12}>
					<CCard>
						<CCardHeader className="d-flex justify-content-between align-items-center">
							<CCardTitle>{_t('positions.orders.title')}</CCardTitle>
						</CCardHeader>
						<CCardBody>
							<CDataTable
								items={orders}
								fields={fields}
								scopedSlots={slots}
								loading={isLoadingOrders}
								onRowClick={handleOrderRowClicked}
								clickableRows
								hover
								striped
							/>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
			<CModal show={closePositionModalShow} onClose={() => closePositionModalToggle(false)}>
				<CModalHeader>
					<CModalTitle>{_t('positions.position.close-position')}</CModalTitle>
				</CModalHeader>
				<CModalBody>
					{showRetryClosingButton && _t('positions.position.issue-new-order')}
					{status === 'open' && _t('positions.position.close-position-question')}
				</CModalBody>
				{permissions?.includes(Permission.MANAGE_POSITIONS) && (
					<CModalFooter className="modal-footer--alternative">
						<CButton color="secondary" onClick={() => closePositionModalToggle(false)}>
							{_t('action.cancel')}
						</CButton>
						<CButton color="danger" onClick={closePositionCall}>
							{_t('positions.position.close')}
						</CButton>
					</CModalFooter>
				)}
			</CModal>
			<UpdatePositionModal position={editPositionShow} onClose={onEditClose} />
		</PageLayout>
	);
};

export default PositionPage;
