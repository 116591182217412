import { AnyAction } from 'redux';
import {
	LOAD_USER_SUCCESS,
	LOGIN_FAILED,
	LOGIN_SUCCEEDED,
	LOGOUT,
	REQUEST_OAUTH_TOKENS,
	SET_LOGIN_ERROR_MESSAGE,
	UPDATE_USER_REFRESH_TOKEN,
	UPDATE_USER_TOKEN,
	IMPERSONATION_SUCCESS,
	IMPERSONATION_STOP,
	SET_TABLE_VISIBLE_COLUMNS,
	TOGGLE_PUSH_NOTIFICATION_TOAST,
} from '../actions';

export enum UserRole {
	Admin = 'ADMIN',
	Trader = 'TRADER',
	Affiliate = 'AFFILIATE',
	Leader = 'LEADER',
	Sales = 'SALES',
	GroupAdmin = 'GROUP_ADMIN'
}

export type VisibleTableKeys = 'CUSTOMERS_TABLE';

export enum Permission {
	CREATE_TRANSACTION = 'CREATE_TRANSACTION',
	VIEW_DASHBOARD = 'VIEW_DASHBOARD',
	VIEW_CUSTOMERS = 'VIEW_CUSTOMERS',
	VIEW_TRANSACTIONS = 'VIEW_TRANSACTIONS',
	VIEW_POSITIONS = 'VIEW_POSITIONS',
	VIEW_SINGLE_POSITION = 'VIEW_SINGLE_POSITION',
	VIEW_RISK = 'VIEW_RISK',
	VIEW_REPORTS = 'VIEW_REPORTS',
	VIEW_GROUPS = 'VIEW_GROUPS',
	MANAGE_GROUPS = 'MANAGE_GROUPS',
	VIEW_INSTRUMENTS = 'VIEW_INSTRUMENTS',
	VIEW_EMAILS = 'VIEW_EMAILS',
	MANAGE_EMAILS = 'MANAGE_EMAILS',
	VIEW_BOOKMARKS = 'VIEW_BOOKMARKS',
	VIEW_CUSTOMER_FIELD_CONFIGURATIONS = 'VIEW_CUSTOMER_FIELD_CONFIGURATIONS',
	VIEW_BONUSES = 'VIEW_BONUSES',
	VIEW_BANNERS = 'VIEW_BANNERS',
	VIEW_ORDERS = 'VIEW_ORDERS',
	VIEW_DOWNTIMES = 'VIEW_DOWNTIMES',
	VIEW_PAYMENT_PROVIDERS = 'VIEW_PAYMENT_PROVIDERS',
	VIEW_WALLETS = 'VIEW_WALLETS',
	VIEW_MASS_BONUSES = 'VIEW_MASS_BONUSES',
	MANAGE_MESSAGES = 'MANAGE_MESSAGES',
	VIEW_AFFILIATES = 'VIEW_AFFILIATES',
	VIEW_COINS = 'VIEW_COINS',
	MANAGE_COINS = 'MANAGE_COINS',
	VIEW_COUNTRIES = 'VIEW_COUNTRIES',
	VIEW_COMPETITIONS = 'VIEW_COMPETITIONS',
	MANAGE_COMPETITIONS = 'MANAGE_COMPETITIONS',
	MANAGE_TRANSACTIONS = 'MANAGE_TRANSACTIONS',
	MANAGE_CUSTOMER_KYC_DOCUMENTS = 'MANAGE_CUSTOMER_KYC_DOCUMENTS',
	MANAGE_WALLETS = 'MANAGE_WALLETS',
	MANAGE_CUSTOMERS = 'MANAGE_CUSTOMERS',
	MANAGE_POSITIONS = 'MANAGE_POSITIONS',
	VIEW_SALES_PEOPLE = 'VIEW_SALES_PEOPLE',
	MANAGE_CALL_PROVIDERS = 'MANAGE_CALL_PROVIDERS',
	VIEW_SALES_DESK = 'VIEW_SALES_DESK',
	MANAGE_SALES_DESK = 'MANAGE_SALES_DESK',
	SEND_MESSAGES = 'SEND_MESSAGES',
	VIEW_LEADS = 'VIEW_LEADS',
	MANAGE_LEADS = 'MANAGE_LEADS',
	MANAGE_LEADS_SALES_PERSON = 'MANAGE_LEADS_SALES_PERSON',
	UPLOAD_LEADS = 'UPLOAD_LEADS',
	CLAIM_LEADS = 'CLAIM_LEADS',
	IMPERSONATE = 'IMPERSONATE',
	MANAGE_MAX_RISK = 'MANAGE_MAX_RISK',
	VIEW_EXCHANGE_RATE = 'VIEW_EXCHANGE_RATE',
	MANAGE_SALES_STATUS = 'MANAGE_SALES_STATUS',
	MANAGE_SALES_STATUS_CUSTOMER = 'MANAGE_SALES_STATUS_CUSTOMER',
	MANAGE_CUSTOMER_ONBOARDING_STEPS = 'MANAGE_CUSTOMER_ONBOARDING_STEPS',
	MANAGE_CURRENCY_CONVERSIONS = 'MANAGE_CURRENCY_CONVERSIONS',
	MANAGE_ORDERS = 'MANAGE_ORDERS',
	BULK_EDIT_LEADS = 'BULK_EDIT_LEADS',
	VIEW_AUDIT_LOGS = 'VIEW_AUDIT_LOGS',
	VIEW_ADAPTERS = 'VIEW_ADAPTERS',
	MANAGE_ADAPTERS = 'MANAGE_ADAPTERS',
	MANAGE_GENERAL_SETTINGS = 'MANAGE_GENERAL_SETTINGS',
	MANAGE_COMMISSION_MODELS = 'MANAGE_COMMISSION_MODELS',
	VIEW_COMMISSION_MODELS = 'VIEW_COMMISSION_MODELS',
	VIEW_REFERRAL_TREE = 'VIEW_REFERRAL_TREE',
	VIEW_SUM_SUB_ENABLED = 'VIEW_SUM_SUB_ENABLED',
	VIEW_SYMBOLS = 'VIEW_SYMBOLS',
	MANAGE_CUSTOMER_SALES_DESK = 'MANAGE_CUSTOMER_SALES_DESK',
	VIEW_GENERAL_SETTINGS = 'VIEW_GENERAL_SETTINGS',
	VIEW_TRADING_OPTIONS = 'VIEW_TRADING_OPTIONS',
	MANAGE_TRADING_OPTIONS = 'MANAGE_TRADING_OPTIONS'
}

export interface IUserState {
	loggingIn: boolean;
	accountId: string | null;
	token: string | null;
	refreshToken: string | null;
	id: string | null;
	name: string | null;
	email: string | null;
	mobilenumber: string | null;
	refKey: string | null;
	avatarUrl: string | null;
	loginError: string | null;
	roles: Array<UserRole>;
	dismissPushNotificationToast: boolean;
	communicationLanguage: string;
	impersonation?: {
		adminToken: string;
	};
	subscriptionsAvailable: boolean;
	permissions: Array<Permission>;
	visibleTableColumns: {
		[tableKey in VisibleTableKeys]: Array<string>;
	};
	groups: Array<number>;
}

const initialCustomerTableVisibleColumns: Array<string> = [
	'name',
	'accountId',
	'activity',
	'country',
	'totalCoinsEarned',
	'createdAt',
	'source',
	'depositTotal',
	'positionsCount',
	'totalReferrals',
	'status',
	'lastTradingActivityDate',
];

const initialState: IUserState = {
	loggingIn: false,
	accountId: null,
	token: null,
	refreshToken: null,
	id: null,
	name: null,
	email: null,
	mobilenumber: null,
	refKey: null,
	avatarUrl: null,
	loginError: null,
	dismissPushNotificationToast: false,
	roles: [],
	subscriptionsAvailable: false,
	communicationLanguage: 'en',
	permissions: [],
	visibleTableColumns: {
		CUSTOMERS_TABLE: initialCustomerTableVisibleColumns,
	},
	groups: [],
};

function userReducer(state: IUserState | undefined, action: AnyAction): IUserState {
	if (typeof state === 'undefined') {
		return initialState;
	}

	switch (action.type) {
		case LOGOUT:
			return {
				...state,
				token: null,
				refreshToken: null,
				name: null,
				avatarUrl: null,
				loginError: null,
				impersonation: undefined,
			};

		case LOGIN_SUCCEEDED:
			return {
				...state,
				loggingIn: false,
				id: action.user.id,
				accountId: action.user.accountId,
				token: action.token,
				refreshToken: action.refreshToken,
				name: action.user.name,
				email: action.user.email,
				mobilenumber: action.user.mobilenumber,
				refKey: action.user.refKey,
				avatarUrl: action.user.avatarUrl,
				roles: action.user.roles,
				loginError: null,
				impersonation: undefined,
				subscriptionsAvailable: action.user.subscriptionsAvailable,
				communicationLanguage: action.user.communicationLanguage,
				permissions: action.user.permissions,
				groups: action.groups
			};

		case LOGIN_FAILED:
			return {
				...state,
				loggingIn: false,
				token: null,
				name: null,
				email: null,
				mobilenumber: null,
				refKey: null,
				avatarUrl: null,
				roles: [],
				loginError: action.message,
				impersonation: undefined,
				subscriptionsAvailable: false,
			};

		case SET_LOGIN_ERROR_MESSAGE:
			return {
				...state,
				loginError: action.message,
			};

		case IMPERSONATION_SUCCESS:
			return {
				...state,
				impersonation: {
					adminToken: state.token!,
				},
				accountId: action.accountId,
				token: action.token,
				name: action.user.name,
				email: action.user.email,
				mobilenumber: action.user.mobilenumber,
				refKey: action.user.refKey,
				avatarUrl: action.user.avatarUrl,
				roles: action.user.roles,
				subscriptionsAvailable: action.user.subscriptionsAvailable,
				permissions: action.user.permissions,
			};

		case IMPERSONATION_STOP:
			return {
				...state,
				impersonation: undefined,
				token: state.impersonation!.adminToken,
			};

		case UPDATE_USER_TOKEN:
			return {
				...state,
				token: action.token,
			};

		case UPDATE_USER_REFRESH_TOKEN:
			return {
				...state,
				refreshToken: action.refreshToken,
			};

		case LOAD_USER_SUCCESS:
			return {
				...state,
				accountId: action.user.accountId,
				name: action.user.name,
				email: action.user.email,
				mobilenumber: action.user.mobilenumber,
				refKey: action.user.refKey,
				avatarUrl: action.user.avatarUrl,
				roles: action.user.roles,
				subscriptionsAvailable: action.user.subscriptionsAvailable,
				communicationLanguage: action.user.communicationLanguage,
				permissions: action.user.permissions,
				groups: action.groups
			};

		case REQUEST_OAUTH_TOKENS:
			return {
				...state,
				loggingIn: true,
			};
		case SET_TABLE_VISIBLE_COLUMNS: {
			return {
				...state,
				visibleTableColumns: {
					...initialState.visibleTableColumns,
					[action.payload.tableKey]: action.payload.cols,
				},
			};
		}

		case TOGGLE_PUSH_NOTIFICATION_TOAST: {
			return {
				...state,
				dismissPushNotificationToast: action.payload,
			};
		}

		default:
			return state;
	}
}

export default userReducer;
